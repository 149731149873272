<template>
    <div class="card staff-result">
        <div v-if="isLoading" class="row" role="status" aria-live="polite">
            <div class="col-xs-12" style="display:flex;justify-content: center; align-items: center;">
                <h2>Hämtar...</h2>
            </div>
        </div>
        <div v-if="!isLoading && staff && staff.length > -1" class="staff-resultsummary" role="status" aria-live="polite">
            <span class="font-weight-bold">Resultat:</span>
            {{ staff.length }} träffar
        </div>
        <div v-if="!isLoading" class="row" aria-live="polite">
            <template v-for="(person, index) in pagedResult">
                <Staffcard :Person="person" v-bind:key="person.Id"></Staffcard>
                <div v-if="index % 2 == 1" v-bind:key="`${person.Id}-rowbreaker`" class="col-xs-12"></div>
            </template>
        </div>
        <div v-if="!isLoading" class="row">
            <nav class="col-xs-12 paging" aria-label="Paginering">
                <jw-pagination 
                    :items="staff" 
                    @changePage="onChangePage" 
                    :pageSize="NumberOfCardsPerPage" 
                    :maxPages="3" 
                    :labels="customLabels" 
                    :pseudoitems="false">
                </jw-pagination>
            </nav>
        </div>
    </div>
</template>
<script>
import Staffcard from './staffcard.vue';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: 'Föregående',
    next: 'Nästa',
    page: 'Gå till sida',
    firstLabel: 'Första sidan',
    lastLabel: 'Sista sidan',
    previousLabel: 'Föregående sida',
    nextLabel: 'Nästa sida',
    currentPage: 'Nuvarande sida'
};

export default {
    name: 'staffresult',
    props: {
        staff: {
            type: Array,
            default: function () {
                return [];
            },
        },
        isLoading: false,
        NumberOfCardsPerPage: {
            type: Number,
            default: 20,
        },
        PagerTexts: {
            type: Object,
            default: {}
        },
        profilePhotos: Array
    },
    data: function () {
        return {
            pagedResult: [],
            customLabels
        };
    },
    components: {
        Staffcard,
    },
    mounted() {
        Object.assign(this.customLabels, this.PagerTexts);
    },
    methods: {
        onChangePage(pageOfItems) {
            // update page of items
            this.pagedResult = pageOfItems;
        }
    }
};
</script>
