﻿
var columnbasedlandingpage = {

    init: function () {
        var blockImages = $(".image-teaser-block img");

        if (blockImages.length > 0) {
            var imgBlocks = $(".image-teaser-block");

            for (index = 0; index < imgBlocks.length; ++index) {
                newWidth = imgBlocks[index].clientWidth;
                var aspectRatio = parseFloat(blockImages[index].getAttribute("data-ratio")).toFixed(3);
                console.log(aspectRatio + "float");
                console.log("newWidth " + newWidth);
                var newHeight = newWidth / aspectRatio;
                blockImages[index].style.height = newHeight + "px";
            }
        }
    }
};

$(document).ready(function () {
    var isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident') > 0;
    if ($(".columnbasedlandingpage").length > 0 && isIE) {
        columnbasedlandingpage.init();
    }
});

