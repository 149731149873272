﻿var foreignGradeCalculator = {

    calculate : function () {
        var validNumber = true;
        $.each($('#ForeignGradeContainer .calculate'), function () {
            var inputValue = $(this).val();
            var errorMessage = $(this).next('.field-validation-error');
            var hideError = false;
            var numberValue = parseFloat(inputValue);
            if (isNaN(numberValue)) {
                validNumber = false;
            } else {
                hideError = true;
            }

            $(this).attr('aria-invalid', !hideError);
            errorMessage.toggleClass('hidden', hideError);
        });
        if (validNumber) {
            var minValue = parseFloat($('#MinValue').val().replace(',', '.'));
            var maxValue = parseFloat($('#MaxValue').val().replace(',', '.'));
            var averageValue = parseFloat($('#AverageValue').val().replace(',', '.'));
            var returnContainer = foreignGradeCalculator.resultReturnContainer();
            foreignGradeCalculator.doCalculation(minValue, maxValue, averageValue, returnContainer);
        }
    },
            
    resultReturnContainer : function () {
        return {
            returnContainer: $('#ResultContainer'),
            calculatedResultContainer: $('#CalculatedResultContainer'),
            resultReturnField: $('#CalculatedValue'),
            formulaReturnField: $('#CalculatedFormula')
        };
    },

    roundit : function (num, places) {
        if (places > 0) {
            if ((num.toString().length - num.toString().lastIndexOf('.')) > (places + 1)) {
                var rounder = Math.pow(10, places);
                return Math.round(num * rounder) / rounder;
            } else return num;
        } else return Math.round(num);
    },

    doCalculation : function (minValue, maxValue, averageValue, resultContainer) {

        var error = false;

        if (!error) {
            var ggr = 10 * (maxValue - averageValue);
            var formula = ggr / (maxValue - minValue);
            var result = 20 - formula;
            result = foreignGradeCalculator.roundit(result, 5);
            var finalResult = foreignGradeCalculator.roundit(result, 2);

            finalResult = finalResult.toString().replace('.', ',');
            maxValue = maxValue.toString().replace('.', ',');
            minValue = minValue.toString().replace('.', ',');
            averageValue = averageValue.toString().replace('.', ',');
            resultContainer.resultReturnField.html(finalResult);

            if (isIE()) {
                resultContainer.formulaReturnField.html(finalResult + ' = 20 - 10(' + maxValue + ' - ' + averageValue + ') / (' + maxValue + ' - ' + minValue + ')');
            } else {
                resultContainer.formulaReturnField.html("<math xmlns = 'http://www.w3.org/1998/Math/MathML'>" +
                    "<mrow><mn>" + finalResult + "</mn><mo>=</mo><mn>20</mn><mo>-</mo><mn>10</mn><mo>(</mo><mn>" + maxValue + "</mn><mo>-</mo><mn>" + averageValue + "</mn><mo>)</mo><mo>&#8725</mo><mo>(</mo><mn>" + maxValue + "</mn><mo>-</mo><mn>" + minValue + "</mn><mo>)</mo></mrow></math>");
            }
        }

        resultContainer.calculatedResultContainer.toggleClass('hide-container', error);

        resultContainer.returnContainer.removeClass('hide-container');
    },

    clearAll : function () {
        $('#CalculatedValue, #CalculatedFormula').html(null);
        $.each($('#ForeignGradeContainer .field-validation-error'), function () {
            if (!$(this).hasClass('hidden')) {
                $(this).addClass('hidden');
            }
        });

        $.each($('#ForeignGradeContainer .calculate'), function () {
            $(this).attr('aria-invalid', false);
        });

        $('#ResultContainer').addClass('hide-container');
    },

    init: function () {

        $('#ResultContainer').addClass('hide-container');

        $(document).on('click', '#Calculate', function () {
            foreignGradeCalculator.clearAll();
            foreignGradeCalculator.calculate();
        });

        $(document).on('click', '#Clear', function () {
            $('#ForeignGradeContainer .calculate').val(null);
            foreignGradeCalculator.clearAll();
        });

        $(document).on('click keypress', '#ShowCalculation', function () {
            if ($('#CalculatedFormula').hasClass('hidden')) {
                $(this).html('Dölj uträkning');
                $('#CalculatedFormula').removeClass('hidden');
            } else {
                $(this).html('Visa uträkning');
                $('#CalculatedFormula').addClass('hidden');
            }
        });

        // Göm felmeddelande när valt input-fält ändras (så att skärmläsare vet när meddelandet presenteras igen)
        $("input.calculate").on("input", function () {
            var inputError = ($(this).siblings(".text-danger"));
            if (inputError.is(":visible")) {
                inputError.addClass("hidden");
            }
        });
    }
};

function isIE() {
    ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  
    return is_ie; 
}

$(document).ready(function() {
    foreignGradeCalculator.init();
});