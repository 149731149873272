<template>
  <div class="loadingModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Laddar...</span>
    </div>    
  </div>
</template>
<script>

export default {
  name: 'loading'
}
</script>