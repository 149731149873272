
uhr.ready(function () {
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutationRecord) {
            if (mutationRecord.target.id === 'ui-id-1') {
                console.log("Inkommande id: " + mutationRecord.target.id)
                searchSuggestionsAccessibility(mutationRecord.target.id);
            } else if (mutationRecord.target.id === 'ui-id-2') {
                searchSuggestionsAccessibility(mutationRecord.target.id);
                console.log("Inkommande id: " + mutationRecord.target.id)
            }
            
        });
    });
    $("#searchBoxInHeaderIdDesktop").each(function () {
        var searchHead = true;
        searchSuggestions($("#searchBoxInHeaderIdDesktop"));

        var target1 = document.getElementById('ui-id-1');
        observer.observe(target1, {
            attributes: true,
            attributeFilter: ['style'],
        });
        
    });
    //Find mobile search-box
    $("#searchBoxInHeaderIdMobile").each(function () {
        var searchHead = true;
        searchSuggestions($("#searchBoxInHeaderIdMobile"));

        var target3 = document.getElementById('ui-id-1');
        observer.observe(target3, {
            attributes: true,
            attributeFilter: ['style'],
        });

    });
    // Find desktop search-box 
    $("#searchQuery").each(function () {
        var searchHead = true;
        searchSuggestions($("#searchQuery"));
        var target2 = document.getElementById('ui-id-2');
        observer.observe(target2, {
            attributes: true,
            attributeFilter: ['style']
            
        });
    });

    function searchSuggestions(searchBox) {
        var inputField = searchBox;
        var form = inputField.parents("form"),
            ajaxUrl = form.attr("data-ajax-url"),
            currentPageId = form.is("[data-current-page-id]") ? form.attr("data-current-page-id") : 0;
        var thisInputSuggestion = '#' + inputField.attr("id") + '-container-search-autocomplete';
        inputField.autocomplete({
            source: function (request, response) {

                $.ajax({
                    url: ajaxUrl,
                    dataType: "json",
                    data: {
                        q: request.term,
                        currentPageId: currentPageId
                    },
                    success: function (data) {
                        response(data);
                    }
                });
            },
            minLength: 3,
            delay: 50,
            appendTo: thisInputSuggestion,

            select: function (event, ui) {
                var url = ui.item.data;
                window.location = url;
            },
            html: true,

        }).data("uiAutocomplete")._renderItem = function (ul, item) {
            var search = this.term.toLowerCase();
            var highlighted = item.label.toLowerCase()
                .split(search)
                .join('<strong>' + search + '</strong>');

            return $('<li class="custom-complete-choice">')
                .append($('<a href="#" class="custom-complete-link"></a>').html(highlighted))
                .appendTo(ul);
        };
    }
    function searchSuggestionsAccessibility(id) {
        var idForSuggestion = id;
        var autoSuggestion = $('#' + idForSuggestion + '');
        var helpText = $('.ui-helper-hidden-accessible');
        var ariaCountMessage = " sökförslag är tillgängliga";
        var ariaNavigateMessage = ", använd upp och ned på piltangenterna för att navigera.";
        var visible = autoSuggestion.css('display') === "none" ? false : true;

        if (visible) {
            var numOfSuggestion = autoSuggestion.children().length;
            helpText.text(numOfSuggestion + ariaCountMessage + ariaNavigateMessage);
        }
        else 
            helpText.text("Inga" + ariaCountMessage + ".");
    }
});

