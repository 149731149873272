import axios from 'axios';

export default {
  install(Vue, options) {
    let csrfToken = options.csrfToken;
    let baseHost = window.location.protocol + '//' + window.location.host;
    let addCommentUrl = baseHost + '/api/comments/comment',
      addChildCommentUrl = baseHost + '/api/comments/childcomment',
      getCommentUrl = baseHost + '/api/comments/content',
      addContentLikeUrl = baseHost + '/api/comments/contentlike',
      removeContentLikeUrl = baseHost + '/api/comments/contentlike?guid=',
      addCommentLikeUrl = baseHost + '/api/comments/commentlike',
      removeCommentLikeUrl = baseHost + '/api/comments/commentlike';
    // use new Vue instance as an event bus
    const commentservice = new Vue();
    // every component will use this.$stops to access the event bus
    Vue.prototype.$commentservice = commentservice;

    commentservice.GetComment = (contentGUID) => {
      var response = axios.get(
        getCommentUrl + '?guid=' + contentGUID + '&cache=false'
      )
      return response;
    };

    commentservice.AddComment = (comment) => {
      axios.defaults.headers.post['X-XSRF-Token'] = csrfToken;
      var response = axios.post(
        addCommentUrl, comment
      )
      return response;
    };

    commentservice.AddChildComment = (comment) => {
      axios.defaults.headers.post['X-XSRF-Token'] = csrfToken;
      var response = axios.post(
        addChildCommentUrl, comment
      )
      return response;
    };

    commentservice.EditComment = (comment) => {
      axios.defaults.headers.put['X-XSRF-Token'] = csrfToken;
      var response = axios.put(
        addCommentUrl, comment
      )
      return response;
    };

    commentservice.RemoveLike = (contentGUID) => {
      axios.defaults.headers.delete['X-XSRF-Token'] = csrfToken;
      return axios.delete(removeContentLikeUrl + contentGUID);
    };

    commentservice.AddLike = (contentGUID) => {
      axios.defaults.headers.post['X-XSRF-Token'] = csrfToken;
      var response = axios.post(
        addContentLikeUrl, {
        Guid: contentGUID
      }
      )
      return response;
    };

    commentservice.AddCommentLike = (commentId) => {
      axios.defaults.headers.post['X-XSRF-Token'] = csrfToken;
      var response = axios.post(
        addCommentLikeUrl, {
        Id: commentId
      }
      )
      return response;
    };

    commentservice.RemoveCommentLike = (commentId) => {
      axios.defaults.headers.delete['X-XSRF-Token'] = csrfToken;
      var response = axios.delete(
        removeCommentLikeUrl
        + '?id=' + commentId
      )
      return response;
    };

    commentservice.RemoveComment = (commentId) => {
      axios.defaults.headers.delete['X-XSRF-Token'] = csrfToken;
      var response = axios.delete(
        addCommentUrl
        + '?id=' + commentId
      )
      return response;
    };

  },
};
