﻿// Customize citations to work as foot notes
//
// Dependencies: Bootstrap popover
// http://getbootstrap.com/javascript/#popovers
//
// Sample usage $(".wrapper").citeToFootnote();
//

; (function ($, window, document, undefined) {

    "use strict";

    // Create the defaults once
    var pluginName = "citeToFootnote",
            defaults = {
                footerListClass: 'visible-print-block',
                citeSel: 'cite:visible',
                toolTipContentAttr: 'title',
                clearTitle: true,
                startNbr: 1,
                tabIndexStart: 100,
                popOverSettings: {
                    trigger: 'focus',
                    toggle: 'popover',
                    placement: 'auto'
                }
            };

    // The actual plugin constructor
    function Plugin(element, options) {
        this.element = element;

        // Merge defaults and options
        this.settings = $.extend({}, defaults, options, true);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function () {
            // Grab the headings from the thead
            var $element = $(this.element),
                settings = this.settings;

            // Foot note list to append to the element
            var citeList = $('<ol/>').addClass(settings.footerListClass);
            var citeNbr = settings.startNbr;

            $element.find(settings.citeSel).each(function () {
                var $cite = $(this);

                var toolTipContent = $cite.attr(settings.toolTipContentAttr);

                // Alter attributes for popover plugin to work
                if (settings.clearTitle) {
                    $cite.attr('title', '');
                }
                
                $cite.attr('role', 'button');
                $cite.attr('tabindex', settings.tabIndexStart + citeNbr);

                $cite.append("<sup>" + citeNbr + "</sup>");

                settings.popOverSettings.content = toolTipContent;

                $cite.popover(settings.popOverSettings);


                $('<li/>')
                    .append(toolTipContent)
                    .appendTo(citeList);

                citeNbr++;
            });

            // Append list to end of main content
            $element.append(citeList);
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);

