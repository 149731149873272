﻿var sunburstChart = {
    init: function () {
        //var currCell = $('.sunburst-wrapper .sunburst-chart .chart-svg .data-row:eq(1)').find('.data-col').not('.no-target').first();
        var firstCell = $('.sunburst-wrapper .sunburst-chart .chart-svg .part-bologna #Bologna-1'),
            currCell = firstCell,
            targetCell,
            indexFromEnd,
            isIE = (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident') > 0),
            isDesktop = sunburstChart.checkViewport(),
            sunburstBlock = $('.sunburst-block');

        if (!isIE && isDesktop) {
            $('.sunburst-wrapper .sunburst-chart .chart-svg').on('focus', function (e) {
                e.preventDefault();
                if (currCell) {
                    currCell.find('a').focus();
                }
            });

            $('.sunburst-wrapper .sunburst-chart .chart-svg a').on('click, focus', function (e) {
                e.preventDefault();
                currCell = $(this).parent('g');
            });

            $('.sunburst-wrapper .sunburst-chart .chart-svg a').on('mouseenter', function (e) {
                e.preventDefault();
                currCell = $(this).parent('g');
                currCell.find('a').focus();
            });

            $('.sunburst-wrapper .sunburst-chart .chart-svg').keyup(function (e) {
                if (!e.shiftKey && e.which == 9) {
                    currCell.find('a').focus();
                }
            });

            $('.sunburst-wrapper .sunburst-chart .chart-svg').keydown(function (e) {
                if (e.which !== 13) {
                    e.preventDefault();
                }
                if (e.which == 9) {
                    if (e.shiftKey) {
                        $('.sunburst-wrapper').focus();
                        currCell = firstCell;
                    } else {
                        $('.sunburst-wrapper .sunburst-labels .btn-sunburst:first').focus();
                    }
                } else {
                    targetCell = '';
                    indexFromEnd = '';

                    if (e.which == 39) {//Right
                        targetCell = currCell.nextAll(':not(.no-target)').first();
                        if (targetCell.length == 0) {
                            targetCell = currCell.closest('.data-row').next('.data-row').find('.data-col').not('.no-target').first();
                        }
                    } else if (e.which == 37) {//Left
                        targetCell = currCell.prevAll(':not(.no-target)').first();
                        if (targetCell.length == 0) {
                            targetCell = currCell.closest('.data-row').prev('.data-row').find('.data-col').not('.no-target').last();
                        }
                    } else if (e.which == 38) {//Up
                        indexFromEnd = (currCell.closest('.data-row').find('.data-col').length - currCell.index()) * -1;
                        targetCell = currCell.closest('.data-row').prev('.data-row').find('.data-col:eq(' + indexFromEnd + ')').not('.no-target');
                        if (targetCell.length == 0) {
                            targetCell = currCell.closest('.data-row').prevAll('.data-row').eq(1).find('.data-col:eq(' + indexFromEnd + ')').not('.no-target');
                        }
                    } else if (e.which == 40) {//Down
                        indexFromEnd = (currCell.closest('.data-row').find('.data-col').length - currCell.index()) * -1;
                        targetCell = currCell.closest('.data-row').next('.data-row').find('.data-col:eq(' + indexFromEnd + ')').not('.no-target');
                        if (targetCell.length == 0) {
                            targetCell = currCell.closest('.data-row').nextAll('.data-row').eq(1).find('.data-col:eq(' + indexFromEnd + ')').not('.no-target');
                        }
                    }

                    if (targetCell.length > 0) {
                        currCell = targetCell;
                        currCell.find('a').focus();
                    }
                }
            });
        }

        if (!isDesktop) {
            if (sunburstBlock.hasClass('bologna')) {
                showModal('bologna', sunburstBlock.attr('id'));
            } else if (sunburstBlock.hasClass('seqf')) {
                showModal('seqf', sunburstBlock.attr('id'));
            } else if (sunburstBlock.hasClass('eqf')) {
                showModal('eqf', sunburstBlock.attr('id'));
            } else if (sunburstBlock.hasClass('kvalifikationer')) {
                showModal('qualifications', sunburstBlock.attr('id'));
            }

            if ($('button.btn-info').hasClass('active')) {
                $('html, body').animate({ scrollTop: $('.sunburst-wrapper').offset().top }, 1000);
            }
        }

        var lastButtonId;
        $('.sunburst-wrapper .sunburst-chart .chart-svg a, .sunburst-wrapper .sunburst-labels .btn-sunburst').on('click', function (e) {
            e.preventDefault();
            var modalTheme = $(this).data('modal-theme');
            lastButtonId = '#' + $(this).attr('id');
            $('body').removeClass('modal-theme-bologna modal-theme-eqf modal-theme-eqf modal-theme-seqf modal-theme-qualifications').addClass(modalTheme);
        });
        $('.solfjader-modal .modal-dialog .modal-content .modal-header .close').on('click', function () {
            setTimeout(function () {
                $(lastButtonId).focus();
            }, 100);
        })
    },
    checkViewport: function () {
        isDesktop = $('.chart-svg').css('z-index') == 1 ? true : false;

        if (!isDesktop) {
            $('.sunburst-wrapper').attr('tabindex', '-1').find('.chart-svg').attr('focusable', 'false').find('.data-col').attr('tabindex', '-1').find('a').attr('tabindex', '-1');
            return false;
        } else {
            $('.sunburst-wrapper').attr('tabindex', '0').find('.chart-svg').attr('focusable', 'true').find('.data-col').attr('tabindex', '0').find('a').removeAttr('tabindex');
            return true;
        }
    }
};

function showModal(framework, level) {
    var modalId = '#' + framework + level;
    $(modalId).show();
}



$(document).ready(function () {
    if ($('.sunburst-wrapper').length) {
        sunburstChart.init();
    }
    //if ($('.filters').length) {
    //    $('html, body').animate({ scrollTop: $('.sunburst-wrapper').offset().top }, 1000);
    //}
});

$(window).on('resizeend', function () {
    sunburstChart.checkViewport();
});