<template>
    <div class="staffcatalog">
        <staffsearch 
            :searchObject="searchObject" 
            :Heading="Heading" 
            :Units="Units" 
            :Departments="Departments" 
            @Search="search" 
            :UnitsText="UnitsText" 
            :DepartmentsText="DepartmentsText">
        </staffsearch>

        <staffresult 
            v-if="!FieldsIsEmpty" 
            :staff="staff" 
            :NumberOfCardsPerPage="NumberOfCardsPerPageNeverZero" 
            :PagerTexts="PagerTexts" 
            :isLoading="isLoading"
            :profilePhotos="profilePhotos">
        </staffresult>
    </div>
</template>
<script>
import Vue from 'vue';
import staffservice from '../plugins/staffservice';
import staffsearch from './staffsearch.vue';
import staffresult from './staffresult.vue';

export default {
    name: 'staffcatalog',
    props: {
        csrftoken: String,
        Heading: String,
        NumberOfCardsPerPage: Number,
        Units: Array,
        Departments: Array,
        UnitsText: String,
        DepartmentsText: String,
        PagerTexts: Object
    },
    data: function () {
        return {
            staff: undefined,
            isLoading: false,
            searchObject: {
                query: '',
                dep: '',
                units: '',
            },
            profilePhotos: []
        };
    },
    components: {
        staffresult,
        staffsearch,
    },
    created() {},
    beforeMount() {
        Vue.use(staffservice, { csrfToken: this.csrftoken });
        this.collectSearchOptionsFromUrl(true);
    },
    methods: {
        buildPath() {
            if (!this.searchObject) return '';
            if ((!this.searchObject.units || this.searchObject.units.length == 0) && (!this.searchObject.dep || this.searchObject.dep.length == 0)) return '/' + encodeURIComponent(this.searchObject.query) + '/';
            return '/' + encodeURIComponent(this.searchObject.query) + '/' + encodeURIComponent(this.searchObject.units) + '/' + encodeURIComponent(this.searchObject.dep) + '/';
        },
        collectSearchOptionsFromUrl(autoSearch) {
            var f = this.$route.path.split('/').slice(1, -1);
            if (f.length > 0 && f[0].length > 0) {
                //query
                var decodedquery = decodeURIComponent(f[0]);
                this.searchObject.query = decodedquery;
            }
            if (f.length > 1 && f[1].length >= 0) {
                //units
                var decodedunits = decodeURIComponent(f[1]);
                this.searchObject.units = decodedunits;
            }
            if (f.length > 2 && f[2].length >= 0) {
                //deps
                var decodeddeps = decodeURIComponent(f[2]);
                this.searchObject.dep = decodeddeps;
            }
            //Finally, search is desirable
            if (autoSearch) {
                this.doSearch();
            }
        },
        routeChangeSearch() {
            this.collectSearchOptionsFromUrl(false);
            this.doSearch();
        },
        search(searchObject) {
            this.searchObject = searchObject;
            if (this.$route.path == this.buildPath()) {
                this.doSearch();
                return;
            }

            this.$router.push({ path: this.buildPath() }, () => {
                //search is triggered by the watch on $route.
            });
        },
        doSearch() {
            if (!this.searchObject || this.FieldsIsEmpty) {
                return;
            }
            this.isLoading = true;
            this.$staffservice
                .findStaff(this.searchObject.query, this.searchObject.units, this.searchObject.dep)
                .then((result) => {
                    if (result && result.status === 200) {
                        this.staff = result.data;
                    }
                    this.isLoading = false;
                })
                .catch((errorResult) => {
                    this.staff = undefined;
                    this.isLoading = false;
                });
        }
    },
    computed: {
        NumberOfCardsPerPageNeverZero() {
            return this.NumberOfCardsPerPage > 0 ? this.NumberOfCardsPerPage : undefined;
        },
        FieldsIsEmpty() {
            return this.searchObject.query.length == 0 && this.searchObject.units.length == 0 && this.searchObject.dep.length == 0;
        },
    },
    watch: {
        $route: 'routeChangeSearch',
    },
};
</script>
