﻿(function ($) {
    var defaultOptions = {
        errorClass: "has-error",
        validClass: "has-success",
        highlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group")
                .addClass(errorClass)
                .removeClass(validClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group")
            .removeClass(errorClass)
            .addClass(validClass);
        },
        // Eager validation
        onfocusout: function (element) { $(element).valid(); }
    };

    $.validator.setDefaults(defaultOptions);

    $.validator.unobtrusive.options = {
        errorClass: defaultOptions.errorClass,
        validClass: defaultOptions.validClass
    };

    $.validator.methods._required = $.validator.methods.required;
    $.validator.methods.required = function (value, element, param) {
        if ($(element).attr('data-val-remote') && !$(element).hasClass('required'))
            return true;
        return $.validator.methods._required.call(this, value, element, param);
    }

})(jQuery);