import Vue from 'vue';
import VueRouter from 'vue-router';
//import axios from 'axios';
//import VueAxios from 'vue-axios';
import UniqueId from 'vue-unique-id'

import JwPagination from './vue/components/jwPaginationCustom.vue';
import comments from './vue/comments.vue';
import staffcatalog from './vue/staffcatalog/staffcatalog.vue';

Vue.use(VueRouter);
//Vue.use(VueAxios, axios); //används inte, släng bort. det ställer till builden
Vue.use(UniqueId)

Vue.config.productionTip = false;

Vue.component('jw-pagination', JwPagination);

Vue.component('comments', comments);
Vue.component('staffcatalog', staffcatalog);

var VuecomponentList = [
  { container: 'comments', component: comments },
  { container: 'staffcatalog', component: staffcatalog },
 ];

VuecomponentList.forEach(item => {
  initVueComponent(item);
});

function initVueComponent(item) {
  var pagebottomTAG = item.container,
    pagebottomDOMs = document.querySelectorAll(pagebottomTAG);
  pagebottomDOMs.forEach(pagebottomDOM => {
    if (pagebottomDOM) {
      var rawoptions = pagebottomDOM.getAttribute('data-settings'),
        options = {};
      if (rawoptions) {
        options = JSON.parse(rawoptions);
      }
      if (item.container == 'staffcatalog') {
        const routes = [{ path: '/', component: staffcatalog, default: true }];
        const router = new VueRouter({ routes });
        new Vue({
          router,
          render: createElement => {
            const context = {
              props: { ...options }
            };
            return createElement(item.component, context);
          }
        }).$mount(item.container);
      } else {
        new Vue({
          render: createElement => {
            const context = {
              props: { ...options }
            };
            return createElement(item.component, context);
          }
        }).$mount(item.container);
      }
    }
  });
}