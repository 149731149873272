<template>
    <div class="comment-list" :class="{ hasComments: Comments && Comments.length > 0, isLastComment: IsLastComment }" style="display: flex; flex-direction: column">
        <div class="comment" :class="{ isChildComment: !ShowCommentForm }">
            <div class="comment-profileImage">
                <ProfilePhoto :PhotoString="Author.PhotoString"></ProfilePhoto>
            </div>
            <div class="comment-content">
                <div class="commentText" :class="{ commentCanEdit: CanEditComment }">
                    <div>
                        <profilelink :Author="Author" :CustomClass="Author.ValidUser ? 'text-decoration-underline' : undefined"></profilelink>
                    </div>
                    <div v-if="!editcomment">{{ CommentText }}</div>
                    <EditForm
                        v-if="editcomment"
                        :editCommentText="CommentText"
                        :contentGUID="contentGUID"
                        :commentId="commentId"
                        :createdDate="CreatedDate"
                        @update="update"
                        @loading="loading"
                        @editcomment="canceledit"
                    ></EditForm>
                    <button v-if="CanEditComment" class="btn comment-editmenu" @click="editMenuVisible = !editMenuVisible" aria-label="Redigera Meny">
                        <svgIcon iconName="skriva" className="commentEditIcon text-decoration-underline"></svgIcon>
                    </button>
                    <div v-if="editMenuVisible" class="comment-editoptionlist">
                        <button
                            class="btn comment-editoption"
                            @click="
                                () => {
                                    editcomment = !editcomment;
                                    editMenuVisible = false;
                                }
                            "
                        >
                            <svgIcon iconName="skriva" className="commentEditOptionIcon text-decoration-underline"></svgIcon>
                            <span>Redigera</span>
                        </button>
                        <button class="btn comment-editoption" @click="editMenuVisible = false" data-toggle="modal" :data-target="`#commentdeletemodal-${commentId}`">
                            <svgIcon iconName="kryss" className="commentEditOptionIcon text-decoration-underline"></svgIcon>
                            <span>Ta bort</span>
                        </button>
                    </div>
                </div>
                <div class="comment-actions">
                    <a class="text-decoration-underline" href="#" @click.prevent="addOrRemoveCommentLike">{{ hasUserLiked ? 'Sluta gilla' : 'Gilla' }}</a>
                    <span class="d-flex ml-1" v-if="ShowCommentForm">
                        <a href="#" class="comment-link text-decoration-underline" ref="btnCommentForm" @click.prevent="answercomment = !answercomment" :aria-label="!answercomment ? 'Svara' : 'Avbryt'">
                            <span>{{ !answercomment ? 'Svara' : 'Avbryt' }}</span>
                        </a>
                    </span>
                    <a class="comment-likecount" href="#" data-toggle="modal" :data-target="`#${modalDialogid}`">
                        <svgIcon iconName="gilla" className="commentLikeIcon text-decoration-underline"></svgIcon>{{ likesCount }}</a>
                    <div class="comment-timestamp">{{ CreatedRelative }}</div>
                    <pagelikemodal :Likes="Likes" :ModalDialogId="modalDialogid"></pagelikemodal>
                </div>
            </div>
        </div>
        <div style="width: calc(100% - 8rem); align-self: flex-end">
            <CommentForm
                v-if="answercomment"
                :CurrentUserPhoto="CurrentUserPhoto"
                :commentId="commentId"
                :contentGUID="contentGUID"
                :parentCommentUser="parentCommentUser"
                @update="update"
                @loading="loading"
                @close="close">
            </CommentForm>
        </div>
        <div v-if="Comments && Comments.length > 0" class="row">
            <div class="col-xs-11 pull-right subcomments">
                <comment
                    v-for="(child, index) in Comments"
                    :key="child.Id"
                    :CommentText="child.Text"
                    :AuthorName="child.Author.Name"
                    :Author="child.Author"
                    :LikesCount="child.LikesCount"
                    :Likes="child.Likes"
                    :CreatedDate="child.Created"
                    :CreatedRelative="child.CreatedRelative"
                    :Comments="child.Comments"
                    :commentId="child.Id"
                    :contentGUID="contentGUID"
                    :UserHasLiked="child.UserHasLiked"
                    :CanEditComment="child.CanEditComment"
                    :ShowCommentForm="false"
                    :IsLastComment="index == Comments.length - 1"
                    @update="update"
                    @loading="loading">
                </comment>
            </div>
        </div>
        <comment-delete :commentId="commentId" :contentGUID="contentGUID" @update="update"></comment-delete>
    </div>
</template>

<script>
import axios from 'axios';
import commentService from '../plugins/commentservice.js';
import comment from './comment.vue';
import CommentForm from './comment-form.vue';
import CommentDelete from './comment-delete.vue';
import EditForm from './edit-form.vue';
import Loading from '../components/loading.vue';
import svgIcon from '../components/svgicon.vue';
import profilelink from '../components/profilelink.vue';
import pagelikemodal from './pagelike-modal.vue';
import ProfilePhoto from '../components/profilePhoto.vue';

export default {
    name: 'comment',
    props: {
        LikesCount: Number,
        Likes: Array,
        Comments: Array,
        AuthorName: String,
        Author: Object,
        CommentText: String,
        CreatedDate: String,
        CreatedRelative: String,
        CurrentUserPhoto: String,
        commentId: Number,
        UserHasLiked: Boolean,
        CanEditComment: Boolean,
        ShowCommentForm: Boolean,
        contentGUID: String,
        parentCommentUser: String,
        IsLastComment: Boolean
    },
    data: function () {
        return {
            answercomment: false,
            editcomment: false,
            hasUserLiked: false,
            likesCount: this.LikesCount,
            isLoading: false,
            showLoading: false,
            editMenuVisible: false
        };
    },
    components: {
        Comment,
        CommentDelete,
        CommentForm,
        EditForm,
        Loading,
        svgIcon,
        profilelink,
        pagelikemodal,
        ProfilePhoto
    },
    created() {
        this.hasUserLiked = this.UserHasLiked;
    },
    computed: {
        modalDialogid: function () {
            return 'commentlikesmodal-' + this.commentId;
        },
    },
    methods: {
        async addOrRemoveCommentLike() {
            if (this.hasUserLiked) {
                this.$commentservice.RemoveCommentLike(this.commentId).then((res) => {
                    if (res.status) {
                        this.hasUserLiked = false;
                        this.likesCount--;
                        this.update();
                    }
                });
            } else {
                this.$commentservice.AddCommentLike(this.commentId).then((res) => {
                    this.hasUserLiked = true;
                    this.likesCount++;
                    this.update();
                });
            }
        },
        update() {
            this.$emit('update', true);
        },
        loading(value) {
            this.$emit('loading', value);
        },
        close() {
            this.$refs.btnCommentForm.click();
        },
        canceledit(value) {
            this.editcomment = value;
        }
    }
};
</script>