<template>
    <div class="PageLike">
      <pagelikesummary v-if="comment" :Likes="reversedLikes" :contentGUID="contentGUID"></pagelikesummary>
      <div class="PageLike-Interaction">
        <button class="btn likeButton" :class="{ 'btn-primary': !comment.UserHasLiked, 'btn-default': comment.UserHasLiked }" @click="addOrRemoveContentLike">
          <svgIcon iconName="gilla"></svgIcon>
          <span>{{ comment.UserHasLiked ? 'Sluta gilla' : 'Gilla' }}</span>
        </button>
      </div>
      <Loading v-if="showLoading"></Loading>
    </div>
</template>
<script>
import Vue from 'vue';
import pagelikesummary from './pagelike-summary.vue';
import Loading from '../components/loading.vue';
import commentService from '../plugins/commentservice.js';
import svgIcon from '../components/svgicon.vue';

export default {
  name: 'pagelike',
  props: {
    csrfToken: String,
    contentGUID: String,
    inputComment: Object
  },
  data: function() {
    return {
      showLoading: false,
      comment: undefined,
      isLoading: false
    }
  },
  components: {
    Loading,
    svgIcon,
    pagelikesummary
  },
  created() {
    Vue.use(commentService, { csrfToken: this.csrfToken });
    this.comment = this.inputComment;
  },
  methods: {
    addOrRemoveContentLike() {
      this.isLoading = true;
      if (this.comment.UserHasLiked) {
        this.$commentservice.RemoveLike(this.contentGUID).then((res) => {
          this.$commentservice.GetComment(this.contentGUID).then(res => {
            this.comment = res.data
            this.isLoading = false;
          })
        });
      } else {
        this.$commentservice.AddLike(this.contentGUID)
        .then((res) => {
          this.$commentservice.GetComment(this.contentGUID).then(res => {
            this.comment = res.data
            this.isLoading = false;
          })
        });
      }
    },
  },
  computed: {
    reversedLikes() {
      return this.comment.Likes ? this.comment.Likes.reverse() : [];
    },
  }
}
</script>