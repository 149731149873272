<template>
  <svg v-if="iconName" :class='iconClass' aria-hidden='true' focusable='false'>
    <use :xlink:href='svgUrl'></use>
  </svg>
</template>
<script>
export default {  
  name: 'svgicon',
  props: {
    iconName: String,
    className: String
  },
  computed: {
    svgUrl() {
      return '/assets/svg/uhriconsprite.svg#icon-' + this.iconName;
    },
    iconClass() {
      return 'icon icon-' + this.iconName + ' ' + this.className
    }
  }
}
</script>
