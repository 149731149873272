<template>
    <div class="staffcatalog-search uhrcolor-lila-10-background" style="padding: 3rem">
        <label :for="makeID('search-field')">{{ Heading }}</label>
        <form v-on:submit.prevent="Search">
            <div class="input-group">
                <div style="position: relative;">
                    <input 
                        :id="makeID('search-field')"
                        v-model="query" 
                        type="text" 
                        class="form-control" 
                        placeholder="Skriv namn, avdelning eller fritext…"
                        :aria-label="`${Heading}. Skriv namn, avdelning eller fritext.`"/>
                    <button
                        class="clearfield-close-button"
                        v-if="query.length > 0"
                        @click="clear"
                        aria-label="Rensa sökord"
                        type="reset">
                        <svgIcon iconName="kryss"></svgIcon>
                    </button>
                </div>
                <span class="input-group-btn">
                    <button class="btn btn-primary staff-searchbtn" type="submit" @click="Search">Sök</button>
                </span>
            </div>
            <div class="row staff-filterrow">
                <div v-if="Departments" class="col-xs-12 col-md-6 col-lg-4">
                    <label aria-label="Avdelning" :for="makeID('departments')">{{ DepartmentsText }}</label>
                    <div class="input-group">
                        <select :id="makeID('departments')" v-model="department" @change="Search">
                            <option value="">Alla avdelningar</option>
                            <option v-for="dep in Departments" :key="dep.Value">{{ dep.Text }}</option>
                        </select>
                    </div>
                </div>
                <div v-if="Units" class="col-xs-12 col-md-6 col-lg-4">
                    <label aria-label="Enhet" :for="makeID('units')">{{  UnitsText }}</label>
                    <div class="input-group">
                        <select :id="makeID('units')" v-model="units" @change="Search">
                            <option value="">Alla enheter</option>
                            <option v-for="unit in Units" :key="unit.Value">{{ unit.Text }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import svgIcon from '../components/svgicon.vue';

export default {
    name: 'staffsearch',
    props: {
        csrftoken: String,
        Heading: String,
        Units: Array,
        UnitsText: { 
            type: String,
            default: 'Enhet'
        },
        DepartmentsText: {
            type: String,
            default: 'Avdelning'
        },
        Departments: Array,
        searchObject: Object,
    },
    data: function () {
        return {
            id: null,
            query: this.searchObject.query,
            department: this.searchObject.dep,
            units: this.searchObject.units,
        };
    },
    components: {
        svgIcon
    },
    created() {
        this.id = this._uid;
    },
    methods: {
        clear() {
            this.query = "";
            //this.update();
        },
        Search() {
            this.$emit('Search', {
                query: this.query,
                units: this.units,
                dep: this.department,
            });
        },
        makeID(name) {
            return `${name}-${this.id}`;
        },
    },
    computed: {},
};
</script>
