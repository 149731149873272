<template>
    <div class="feedback-comment">
      <label :for="$id('comment-textArea')">Skriv en kommentar</label>
      <div class="comment-profileImage">
        <ProfilePhoto :PhotoString="CurrentUserPhoto"></ProfilePhoto>
      </div>
      <div class="comment-form">
        <div>
          <textarea
            :id="$id('comment-textArea')"
            class="comment-textarea"
            v-model="commentText"
            placeholder=""
            :maxlength="maxlength"
            ref="commentTxt"
            :aria-invalid="validated">
          </textarea>
          <div class="comment-error" v-if="validated" role="alert">
              <span>Du har inte skrivit någon kommentar.</span>
          </div>
        </div>
        <div class="comment-button">
          <button 
            class="btn btn-primary font-weight-bold font-size20" 
            @click="addComment">
            Skicka in
          </button>
        </div>
      </div>
    </div>
</template>

<script>
import ProfilePhoto from "../components/profilePhoto.vue";

export default {
  name: 'commentform',
  props: {
      CurrentUserPhoto: String,
      contentGUID: String,
      commentId: Number,
      parentCommentUser: String
  },
  data() {
    return {
        commentText: '',
        isLoading: false,
        maxlength: 1999,
        isValidationAllowed: false
    }
  },
  components: {
    ProfilePhoto
  },
  mounted() {
    this.$refs.commentTxt.focus({
      preventScroll: true
    });
  },
  computed: {
    validated() {
      return this.isValidationAllowed && !this.commentText
    }
  },
  methods:{
    async addComment() {
        try {
          this.validate();
          if(!(this.isValidationAllowed && !this.commentText)) {
            this.isLoading = true;
            this.$emit('loading', true);  
              if (!this.commentId) {
                this.$commentservice.AddComment({
                  Guid: this.contentGUID,
                  Text: this.commentText,
                }).then((res) => {
                  this.finalizeComment();        
                });        
              } else {
                this.$commentservice.AddChildComment({
                  Id: this.commentId,
                  Guid: this.contentGUID,
                  Text: this.commentText,
                  Username: this.parentCommentUser
                }).then((res) => {
                  this.$emit('close', true);
                  this.finalizeComment();
                });
              }
          }
      } catch (err) {
        this.isError = true;
        
      } finally {        
      }
    },
    finalizeComment() {
      this.commentText = '';
      this.isLoading = false;
      this.isValidationAllowed = false;
      this.$emit('update', true);
    },
    validate() {
      this.isValidationAllowed = true;
    }
  }
}
</script>