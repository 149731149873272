﻿var educationSeachpage = {
  init: function () {
    var lang = $('html').attr('lang');

    function createFlag(option) {
      return "<img class='flag-img' src='" + option.IconUri + "' style='width:24px;height:20px' aria-hidden='true' />";
    }

    function getInputWrapper() {
      return $('.selectize-input');
    }

    function getInput() {
      return $('.selectize-input').find('input');
    }

    function getFlagImage() {
      return $('.selectize-input').find('img');
    }

    function hideResults() {
      $('.js-result-container').hide();
    }

    function getCrossButton() {
      return $('#js-clear-selected-country');
    }

    $('.show-text').keyup(function (e) {
      if ($('.show-more').css('display') === 'inline') {
        e.stopPropagation();
        var maxHeight = infoText[0].scrollHeight;
        infoText.animate({
          height: maxHeight,
        });
        infoText.css('max-height', 'none');
        $('.show-less').show();
        $('.show-more').hide();
        infoText[0].setAttribute('aria-expanded', 'true');
        infoText.removeClass('expandable');
      } else if ($('.show-less').css('display') === 'inline') {
        e.stopPropagation();
        if ($(window).width() < 992) {
          infoText.animate({
            height: '115px',
          });
        } else {
          infoText.animate({
            height: '240px',
          });
        }
        $('.show-less').hide();
        $('.show-more').show();
        infoText[0].setAttribute('aria-expanded', 'false');
        infoText.addClass('expandable');
      }
    });
  },
};

var educationComparison = {
  init: function () {
    $('[data-toggle="tooltip"]').tooltip();
  },
};

var bedomningCountrySearch = {
  init: function () {
    var currentIndex = -1; // Variable to track the current index of highlighted item
    var $dropdownItems = $('#dropdownMenu li'); // Cache dropdown items for efficiency

    var dropdownButton = $('#dropdownButton');
    var input = $('#comboboxInput');
    var iconDownArrow = dropdownButton.find('.icon-triangel-pil-ned');
    var iconUpArrow = dropdownButton.find('.icon-triangel-pil-upp');

    // Function to handle filtering of dropdown options
    function filterDropdown() {
      var searchValue = $('#comboboxInput').val().toLowerCase();

      // Show the dropdown menu if it isn't already visible
      if (!$('#dropdownMenu').hasClass('open')) {
        $('#dropdownMenu').addClass('open').show();
      }

      // Filter the dropdown items based on the text in the .namn span
      $dropdownItems.each(function () {
        var optionText = $(this).find('.namn').text().toLowerCase(); // Search within the .namn span
        $(this).toggle(optionText.indexOf(searchValue) > -1);
      });

      var visibleItems = $dropdownItems.filter(':visible');
      if (visibleItems.length === 0) {
        $('.emptySearch').show();
      } else {
        $('.emptySearch').hide();
      }
    }

    // Update input value and navigate when an option is clicked
    $(document).on('click', '.dropdown-option', function (e) {
      e.preventDefault();
      var selectedOption = $(this).find('.namn').text(); // Get the option's label from the .namn span
      $('#comboboxInput').val(selectedOption);

      var url = $(this).find('a').attr('href'); // Get the URL from the href attribute
      window.location.href = url; // Redirect to the URL

      $('#dropdownMenu').hide(); // Close dropdown after selection
      currentIndex = -1; // Reset index
    });

    // Handle Arrow Up (38), Arrow Down (40), and Enter (13) key events
    $('#comboboxInput').on('keydown', function (e) {
      var visibleItems = $('#dropdownMenu li:visible'); // Only select visible items
      if (e.keyCode === 40) {
        // Arrow Down
        currentIndex = (currentIndex + 1) % visibleItems.length;
        highlightOption(visibleItems);
      } else if (e.keyCode === 38) {
        // Arrow Up
        currentIndex = (currentIndex - 1 + visibleItems.length) % visibleItems.length;
        highlightOption(visibleItems);
      } else if (e.keyCode === 13 && currentIndex !== -1) {
        // Enter
        var selectedOption = visibleItems.eq(currentIndex).find('.namn').text();
        $('#comboboxInput').val(selectedOption);

        var url = visibleItems.eq(currentIndex).find('a').attr('href'); // Get the URL from the href attribute
        window.location.href = url; // Redirect to the URL

        $('#dropdownMenu').hide(); // Close dropdown after selection
        currentIndex = -1; // Reset index
      }
    });

    // Show menu on focus combobox
    $('#comboboxInput').on('focus', function (e) {
      setTimeout(function () {
        showMenu();
      }, 100);
    });

    $('#comboboxInput').on('keyup', function (e) {
      var charCode = event.keyCode;

      // Tab
      if (charCode == 9) {
        return;
      }

      // Letters including åäö
      var charKey = e.key;
      if (
        (charKey >= 'A' && charKey <= 'Z') || // A-Z
        (charKey >= 'a' && charKey <= 'z') || // a-z
        charKey === 'å' ||
        charKey === 'Å' || // å (lowercase and uppercase)
        charKey === 'ä' ||
        charKey === 'Ä' || // ä (lowercase and uppercase)
        charKey === 'ö' ||
        charKey === 'Ö' // ö (lowercase and uppercase)
      ) {
        filterDropdown();
      }

      //Delete or backspace
      if (charCode == 46 || charCode == 8) {
        var select_land = $('#comboboxInput').val();
        var original_land = $('#comboboxInput').attr('value');
        var flag_img = $('#input-group-combobox').find('.flag-img');
        if (select_land != original_land) {
          flag_img.css('visibility', 'hidden');
        } else if (select_land.trim().length > 0 && select_land.trim() === original_land.trim()) {
          flag_img.css('visibility', 'visible');
        }
      }
    });

    // Highlight the currently selected option in the dropdown
    function highlightOption(visibleItems) {
      visibleItems.removeClass('active'); // Remove active class from all items
      visibleItems.eq(currentIndex).addClass('active'); // Add active class to current item
    }

    // Open/close dropdown on button click
    $('#dropdownButton').on('click', function () {
      showHideMenu();
    });

    // Toggle menu
    function showHideMenu() {
      if (!$('#dropdownMenu').hasClass('open')) {
        showMenu();
      } else {
        hideMenu();
      }
    }

    function hideMenu() {
      dropdownButton.attr('aria-expanded', false);
      input.attr('aria-expanded', false);

      $('#dropdownMenu').removeClass('open').hide();

      iconDownArrow.show();
      iconUpArrow.hide();
    }

    function showMenu() {
      dropdownButton.attr('aria-expanded', true);
      input.attr('aria-expanded', true);

      $('#dropdownMenu').addClass('open').show();
      iconDownArrow.hide();
      iconUpArrow.show();
    }

    // Close dropdown if clicked outside of it
    $(document).on('click', function (e) {
      if (!$(e.target).closest('.input-group, #dropdownMenu').length) {
        if ($('#dropdownMenu').hasClass('open')) {
          showHideMenu();
        }
      }
    });
  },
};

$(document).ready(function () {
  if ($('.combobox-countries').length) {
    bedomningCountrySearch.init();
  }

  if ($('.bedomnings-jamforelse-tjanst').length) {
    educationSeachpage.init();
  } else if ($('.bedomnings-tjanst-comparison-page').length) {
    educationComparison.init();
  }
});
