<template>
    <ul v-if="pager.pages && pager.pages.length && items.length > pageSize" class="pagination" :style="ulStyles">
        <li class="page-item first" :class="{'disabled': pager.currentPage === 1}" :style="[liStyles, {display: 'none'}]">
            <a class="page-link" href="#" @click.prevent="setPage(1)" :style="aStyles" :aria-label="labels.firstLabel">{{labels.first}}</a>
        </li>

        <li class="page-item previous" :class="{'disabled': pager.currentPage === 1}" :style="liStyles">
            <a class="page-link mobile" href="#" @click.prevent="setPage(pager.currentPage - 1)" :style="aStyles"  :aria-label="labels.previousLabel">{{labels.previous}}</a>
            <a class="page-link" href="#" @click.prevent="setPage(pager.currentPage - 1)" :style="aStyles"  :aria-label="labels.previousLabel">{{labels.previousLabel}}</a>
        </li>

         <li v-if="pseudoitems" class="page-item skipbreaker"></li>
         
         <li v-for="page in pager.pages" :key="page" class="page-item page-number" :class="{'active': pager.currentPage === page, 'mobile-free': pager.currentPage !== page && !(pager.currentPage === 1 || pager.currentPage === pager.totalPages)}" :style="liStyles">
            <a class="page-link" href="#" @click.prevent="setPage(page)" :style="aStyles" :aria-current="(pager.currentPage === page) ? 'page': undefined" :aria-label="PageNumberLabel(page, pager.currentPage === page)">{{page}}</a>
        </li>

        <li v-if="pseudoitems" class="page-item skipbreaker"></li>

        <li class="page-item next" :class="{'disabled': pager.currentPage === pager.totalPages}" :style="liStyles">
            <a class="page-link mobile" href="#" @click.prevent="setPage(pager.currentPage + 1)" :style="aStyles"  :aria-label="labels.nextLabel">{{labels.next}}</a>
            <a class="page-link" href="#" @click.prevent="setPage(pager.currentPage + 1)" :style="aStyles"  :aria-label="labels.nextLabel">{{labels.nextLabel}}</a>
        </li>

        <li class="page-item last" :class="{'disabled': pager.currentPage === pager.totalPages}" :style="[liStyles, {display: 'none'}]">
            <a class="page-link" href="#" @click.prevent="setPage(pager.totalPages)" :style="aStyles"  :aria-label="labels.lastLabel">{{labels.last}}</a>
        </li> 
    </ul>
</template>

<script>
//import paginate from 'jw-paginate';
import paginate from './jw-paginate';

const defaultLabels = {
    first: 'First',
    last: 'Last',
    previous: 'Previous',
    next: 'Next',
    page: 'Page',
    firstLabel: 'First page',
    lastLabel: 'Last page',
    previousLabel: 'Previous page',
    nextLabel: 'Next page',

};

const defaultStyles = {
    ul: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    li: {
        listStyle: 'none',
        textAlign: 'center',
        marginRight: '8px',
    },
    a: {
        cursor: 'pointer',
        padding: '10px 15px',
    }
};

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        initialPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 3
        },
        maxPages: {
            type: Number,
            default: 20
        },
        labels: {
            type: Object,
            default: () => defaultLabels
        },
        styles: {
            type: Object
        },
        disableDefaultStyles: {
            type: Boolean,
            default: false
        },
        pseudoitems: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            pager: {},
            ulStyles: {},
            liStyles: {},
            aStyles: {}
        }
    },
    created () {
        if (!this.$listeners.changePage) {
            throw 'Missing required event listener: "changePage"';
        }

        // set default styles unless disabled
        if (!this.disableDefaultStyles) {
            this.ulStyles = defaultStyles.ul;
            this.liStyles = defaultStyles.li;
            this.aStyles = defaultStyles.a;
        }

        // merge custom styles with default styles
        if (this.styles) {
            this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
            this.liStyles = { ...this.liStyles, ...this.styles.li };
            this.aStyles = { ...this.aStyles, ...this.styles.a };
        }

        // set to initial page
        this.setPage(this.initialPage, true);
    },
    methods: {
        setPage(page, init) {
            const { items, pageSize, maxPages } = this;

            // get new pager object for specified page
            const pager = paginate(items.length, page, pageSize, maxPages);

            // get new page of items from items array
            const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

            // update pager
            this.pager = pager;

            // emit change page event to parent component
            this.$emit('changePage', pageOfItems);

            if (!init) {
                setTimeout(() => {
                    // Timeout because otherwise element won't be found
                    const firstItem = document
                        .getElementById(`staff-card-${pageOfItems[0].Id}`)
                        .querySelector(".staff-card-profileLink");
                    firstItem.focus();
                }, 1);
            }
        },
        PageNumberLabel(pageNumber, isCurrentPage) {
            return isCurrentPage ? `${pageNumber}, ${this.labels.currentPage}` : `${pageNumber}. ${this.labels.page} ${pageNumber}`
        },
    },
    watch: {
        items () {
            this.setPage(this.initialPage);
        }
    }
}
</script>