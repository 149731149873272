﻿// Custom reflow of columns in a table
//
// Sample usage $("table").reflowColumns({
//                  breakPoint: 3
//              });
//
// Where break piont is the number of columns that should be left alone.
// The rest will be transformed to rows.
//
// Ex. 
// Heading1 | Heading2 | Heading3 | Heading4 | Heading5
// Value1_1 | Value1_2 | Value1_3 | Value1_4 | Value1_5
// Value2_1 | Value2_2 | Value2_3 | Value2_4 | Value2_5
//
// Would be transformed to
//
// Heading1     | Heading2 | Heading3
// Value1_1     | Value1_2 | Value1_3
// Heading 4    | Value1_4
// Heading 5    | Value1_5
// Value2_1     | Value2_2 | Value2_3
// Headingd 4   | Value2_4
// Headingd 5   | Value2_5


; (function ($, window, document, undefined) {

    "use strict";

    // Create the defaults once
    var pluginName = "reflowColumns",
            defaults = {
                breakPoint: 2
            };

    // The actual plugin constructor
    function Plugin(element, options) {
        this.element = element;

        // Merge defaults and options
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }   

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function () {
            // Grab the headings from the thead
            var $element = $(this.element),
                settings = this.settings;

            var reflowHeadings = $element.find('tr').first().find(' td:gt(' + settings.breakPoint + ')').hide();

            // Loop over the rows in the tbody and alter the markup
            $element.find('tr').not(':first').each(function () {
                var row = $(this);
                var allCells = row.find('td');
                var reflowCells = allCells.filter('td:gt(' + settings.breakPoint + ')').hide();
                reflowCells = $(reflowCells.get().reverse());

                var colspanForLastInjectedCell = (allCells.length - reflowCells.length) - 1;

                $(reflowHeadings.get().reverse()).each(function (index) {
                    var headingCell = $(this),
                        valueCell = $(reflowCells[index]);

                    var reflowRow = $("<tr class='reflow'><td>" + headingCell.html() + "</td><td colspan=\""+ colspanForLastInjectedCell +"\">" + valueCell.html() + "</td></tr>");

                    row.after(reflowRow);
                });
            });
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);

