<template>
    <div v-if="Likes" class="PageLike-Summary">
      <div v-if="Likes.length == 0">Bli den första att gilla detta inlägg.</div>
      <div v-if="Likes.length == 1">
        <profilelink :Author="firstPerson"></profilelink> gillar detta.
        
      </div>
      <div v-if="Likes.length == 2">
        <profilelink :Author="firstPerson"></profilelink> och <profilelink :Author="secondPerson"></profilelink> gillar detta.
      </div>
      <div v-if="Likes.length > 2">
        <profilelink :Author="firstPerson"></profilelink>, <profilelink :Author="secondPerson"></profilelink> och <a href="#" class="text-decoration-underline" data-toggle="modal" :data-target="`#${modalDialogid}`">{{sumOfOthers}} andra</a> gillar detta.
      </div>
      <pagelikemodal :Likes="Likes" :ModalDialogId="modalDialogid"></pagelikemodal>
    </div>
</template>
<script>
import Vue from 'vue';
import pagelikemodal from './pagelike-modal.vue';
import profilelink from '../components/profilelink.vue';

export default {
  name: 'pagelikesummary',
  props: {
    contentGUID: String,
    Likes: Array
  },
  data: function() {
    return {
    }
  },
  components: {
    pagelikemodal,
    profilelink
  },
  created() {
  },
  methods: {
  },
  computed: {
    modalDialogid: function () {
      return 'commentlikesmodal-' + this.contentGUID;
    },
    firstPerson() {
      if (this.Likes && this.Likes.length > 0) {
        return this.Likes[0].Author;
      }
    },
    secondPerson() {
      if (this.Likes && this.Likes.length > 1) {
              return this.Likes[1].Author;
            }
    },
    sumOfOthers() {
      if (!this.Likes) { return 0 };
      if (this.Likes.length > 2) { return this.Likes.length - 2; }
      return this.Likes.length;
    }
  }
}
</script>