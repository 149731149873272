import axios from 'axios';

export default {
  install(Vue, options) {
    let csrfToken = options.csrfToken;
    let baseHost = window.location.protocol + '//' + window.location.host;
    let findStaffUrl = baseHost + '/api/staff/search';
        
    // Use new Vue instance as an event bus
    const staffservice = new Vue();
    // Every component will use this.$stops to access the event bus
    Vue.prototype.$staffservice = staffservice;

    staffservice.findStaff = (query, units, departments) => {
      axios.defaults.headers.get['X-XSRF-Token'] = csrfToken;
      var response = axios.get(
        findStaffUrl + '?q=' + query + '&u=' + units + '&d=' + departments
      )
      return response;
    };
  }
};
