<template>
  <div class="col-xs-12 col-md-6">
    <div :id="`staff-card-${Person.Id}`" class="card staff-card border" :aria-label="Person.DisplayName">
      <div class="row">
        <div class="col-sm-9 col-xs-8">
          <a :href="Person.SharePointUrl" class="staff-card-profileLink" target="_blank" :aria-label="`Besök profil för ${Person.DisplayName} i Delve, öppnas i ny flik`">
            <div class="staff-card-profilePhoto hidden-xs">
              <ProfilePhoto :PhotoString="Person.PhotoString"></ProfilePhoto>
            </div>
            <div class="staff-card-displayName">{{ Person.DisplayName }}</div>
          </a>
          <div v-if="formatPhone" class="staff-card-officePhone">{{ formatPhone }}</div>
        </div>
        <div class="col-sm-3 col-xs-4 mail-container">
          <a :href="mailLink" class="mail-btn btn black-text" :aria-label="`Skicka e-post till ${Person.DisplayName}`">
            <Svgicon iconName="kuvert"></Svgicon>
            E-post
          </a>
        </div>
      </div>
      <div>{{ Person.Department }}</div>
      <div>{{ Person.CompanyName }}</div>
    </div>
  </div>
</template>

<script>
import Svgicon from '../components/svgicon.vue';
import ProfilePhoto from '../components/profilePhoto.vue';

export default {
  name: 'staffcard',
  props: {
    Person: Object,
    ProfilePhoto: String,
  },
  data: function () {
    return {};
  },
  components: {
    Svgicon,
    ProfilePhoto,
  },
  computed: {
    mailLink() {
      if (!this.Person || !this.Person.Email) return '';
      return 'mailto:' + this.Person.Email;
    },
    formatPhone() {
      if (!this.Person.OfficePhone || this.Person.OfficePhone.length == 0) return undefined;
      return this.Person.OfficePhone[0];
    },
    base64String() {
      return `data:img/jpeg;base64,${this.ProfilePhoto}`;
    },
  },
};
</script>
