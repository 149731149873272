﻿var antagningsstatistik = window.antagningsstatistik || {};

antagningsstatistik.sokblock = (function ($) {
  const KEY = { ENTER: 13, ARROW_UP: 38, ARROW_DOWN: 40, ESCAPE: 27, TAB: 9 };
  const SEARCHSTRING_MIN_LIMIT = 3;
  const WAIT_FOR = 300;
  var userInput = '',
    suggestionsArray = [],
    currentFocus = -1,
    $searchSelect,
    $searchField,
    $searchSuggestions,
    $toolTip,
    $searchApiAdress,
    $pageSize,
    $clearButton,
    $form;

  function init() {
    $searchSelect = $('.AstaSearch .SearchSelect');
    $searchField = $('.AstaSearch .SearchField');
    $searchSuggestions = $('.AstaSearch .SearchSuggestions');
    $toolTip = $('[data-toggle="tooltip"]');
    $searchApiAdress = $('#searchApiAdress');
    $pageSize = $('#searchSuggetionSize');
    $clearButton = $('.clear-button');
    $form = $('.AstaForm');
    initListeners();
  }
  function initListeners() {
    $searchSelect.change(doSearchRequest);
    $searchField.keyup(keyPressHandle);
    $(document).mouseup(hideSearchSuggestions);
    $searchField.click(scrollToTopMobile);
    $toolTip.tooltip({ animation: true });
    $clearButton.click(ClearButton);
    $(document).ready(toggleClearButton);
  }

  function scrollToTopMobile() {
    if (window.innerWidth < 480) {
      $('html, body').animate(
        {
          scrollTop: $searchSelect.offset().top,
        },
        2000
      );
    }
  }

  function hideSearchSuggestions(e) {
    if (!$searchSuggestions.is(e.target) && !$searchField.is(e.target)) {
      $searchSuggestions.hide();
    }
  }

  function keyPressHandle(event) {
    var keyPressed = event.which;
    var searchVal = $searchField.val();
    var searchSuggest = $searchSuggestions;
    if (searchSuggest) searchSuggest = searchSuggest.children();
    if (searchVal.endsWith(' ')) {
      var temp = $.trim(searchVal);
      searchVal = temp;
    }

    if (searchVal.length >= SEARCHSTRING_MIN_LIMIT) {
      switch (keyPressed) {
        case KEY.ARROW_UP:
          currentFocus--;
          addActive(searchSuggest, currentFocus);
          break;
        case KEY.ARROW_DOWN:
          currentFocus++;
          addActive(searchSuggest, currentFocus);
          break;
        case KEY.ESCAPE:
          userInput = searchVal;
          $searchSuggestions.hide();
          break;
        default:
          var searchterm = $searchSelect.val();
          userInput = searchVal;
          doAstaSuggestionSearch($searchApiAdress.val(), $pageSize.val(), searchVal, searchterm);
      }
    } else {
      $searchSuggestions.html('');
      $searchSuggestions.hide();
    }
    toggleClearButton();
  }

  function doSearchRequest() {
    var searchterm = $searchSelect.val();
    var searchVal = $searchField.val();

    if (searchVal.length >= SEARCHSTRING_MIN_LIMIT) {
      doAstaSuggestionSearch($searchApiAdress.val(), $pageSize.val(), searchVal, searchterm);
    }
  }

  function toggleClearButton() {
    if ($searchField.val() === '') {
      // If text is empty,
      $clearButton.hide();
    } else {
      // Text found
      $clearButton.show();
    }
  }
  function ClearButton(e) {
    e.preventDefault();
    $searchField.val('');
    toggleClearButton();
    $searchSuggestions.hide();
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function doAstaSuggestionSearch(searchAdress, pageSize, searchFor, searchterm) {
    var currentRequest = null;
    var domain = searchAdress;
    var pagesize = pageSize;
    if (pagesize === '0') {
      return;
    }
    var path = '/api/antagningsstatistik/suggest';
    var parameter = '?searchfor=' + searchFor + '&searchterm=' + searchterm + '&pagesize=' + pagesize;
    var _url = domain + path + parameter;
    await sleep(WAIT_FOR);
    var searchVal = $searchField.val();
    if (searchVal !== searchFor) {
      return;
    }

    currentRequest = $.ajax({
      url: _url,
      type: 'GET',
      datatype: 'application/json',
      async: true,
      cache: false,
      crossDomain: false,
      beforeSend: function () {
        if (currentRequest != null) {
          currentRequest.abort();
        }
      },
      success: function (data, textStatus, jqXHR) {
        showSuggestions(data, searchterm);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log('Failed! ' + jqXHR + ', ' + textStatus + ', ' + errorThrown);
      },
    });
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function showSuggestions(searchResult, searchterm) {
    var suggestions = searchResult.data;
    suggestionsArray = suggestions;
    $searchSuggestions.html('');
    var i = 0;
    var searchVal = $searchField.val();
    if (suggestions.length >= 1 && searchVal.length >= SEARCHSTRING_MIN_LIMIT) {
      if ($searchField.is(':focus')) {
        $searchSuggestions.show();

        suggestions.forEach((element) => {
          var href = $form.prop('action') + '?astasearchfor=' + element.ord + '&astasearchperiod=' + searchterm + '&astasearchcategory=' + element.kategori;
          var content = $('<span class="nyckelord">' + capitalizeFirstLetter(highlightSearch(searchVal, element.ord)) + '</span><span class="kategori" >' + element.kategori + '</span>');
          var link = $('<a href="' + href + '"></a>').html(content);
          var card = $('<div class="card"></div>').html(link);
          $searchSuggestions.append(card);
        });
        var finalCard = $('<button class="finalCard">Visa alla resultat</button>');
        $searchSuggestions.append(finalCard);
      } else {
        $searchSuggestions.hide();
      }
    }
    if (suggestions.length == 0) {
      $searchSuggestions.html('');
      $searchSuggestions.hide();
    }
  }

  function addActive(searchSuggest, currentFocus1) {
    /*a function to classify an item as "active":*/
    if (!searchSuggest) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(searchSuggest);

    if (currentFocus1 >= searchSuggest.length) currentFocus = 0;
    if (currentFocus1 < 0) currentFocus = searchSuggest.length - 1;
    /*add class "autocomplete-active":*/
    $(searchSuggest[currentFocus]).addClass('autocomplete-active');
    if (suggestionsArray[currentFocus]) {
      $searchField.val(capitalizeFirstLetter(suggestionsArray[currentFocus].ord));
      $('[name=astasearchcategory]').val(suggestionsArray[currentFocus].kategori);
    } else {
      $('.SearchField').val(userInput);
      $('[name=astasearchcategory]').val('');
    }
  }

  function removeActive(searchSuggest) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < searchSuggest.length; i++) {
      $(searchSuggest[i]).removeClass('autocomplete-active');
    }
  }

  function highlightSearch(searchText, ord) {
    if (searchText !== '') {
      var searchTextLower = searchText.toLowerCase();
      var ordLower = ord.toLowerCase();
      let re = new RegExp(searchTextLower, 'g'); // search for all instances
      let newText = ordLower.replace(re, `<span class="highlighted">${searchTextLower}</span>`);
      ordLower = newText;
      return ordLower;
    }
  }

  // 'Public'
  return {
    init: init,
  };
})(jQuery);

// Init when DOM is ready
jQuery(antagningsstatistik.sokblock.init);
