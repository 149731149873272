<template>
    <div class="PageComments">
      <div>
        <pagelike 
          v-if="results" 
          :csrfToken="csrftoken" 
          :contentGUID="contentGUID" 
          :inputComment="results">
        </pagelike>

        <div class="commentCount" v-if="results && results.CommentsCount > 1">
          {{results.CommentsCount}} Kommentarer
        </div>

        <div class="commentCount" v-if="results && results.CommentsCount == 1">
          {{results.CommentsCount}} Kommentar
        </div>

        <div class="commentCount" v-if="results && results.CommentsCount == 0">
          Bli den första att kommentera detta inlägg.
        </div>
      </div>

      <div v-if="results && results.Comments">
        <Comment
          v-for="comment in results.Comments" 
          :key="comment.Id"
          :CommentText="comment.Text"
          :AuthorName="comment.Author.Name"
          :Author="comment.Author"
          :LikesCount="comment.LikesCount"
          :Likes="comment.Likes"
          :CreatedDate="comment.Created"
          :CreatedRelative="comment.CreatedRelative"
          :Comments="comment.Comments"
          :CurrentUserPhoto="currentUserPhoto"
          :commentId="comment.Id"
          :contentGUID="contentGUID"
          :UserHasLiked="comment.UserHasLiked"
          :CanEditComment="comment.CanEditComment"
          :ShowCommentForm="true"
          :parentCommentUser="comment.AuthorID"
          @update="update"
          @loading="loading">
        </Comment>
      </div>

      <div style="display:flex;gap:2rem;">
        <CommentForm
          :CurrentUserPhoto="currentUserPhoto"
          :contentGUID="contentGUID"
          @update="update"
          @loading="loading">
        </CommentForm>
      </div>

      <Loading v-if="showLoading"></Loading>
    </div>
</template>

<script>
import Vue from 'vue';
import commentService from '../vue/plugins/commentservice';
import CommentForm from './comments/comment-form.vue';
import Comment from './comments/comment.vue';
import Loading from './components/loading.vue';
import pagelike from './comments/pagelike.vue';

export default {
  name: 'comments',
  props: {
    csrftoken: String,
    currentUserPhoto: String,
    contentGUID: String
  },
  data: function() {
    return {
      results: undefined,
      cache: true,
      showLoading: false
    }
  },
  components: {
	  Comment,
    CommentForm,
    Loading,
    pagelike
  },
  beforeMount() {
    Vue.use(commentService, { csrfToken: this.csrftoken });
    this.fetchData();
  },
  methods: {
    async fetchData(update) {     
      if (update !== undefined) {
        this.cache = update;
      }

      try {
        this.isLoading = true;
        this.showLoading = true;
        this.$commentservice.GetComment(this.contentGUID).then((response) => {
          this.results = response.data;
        });
        
      } catch (err) {
        console.error(err)
        this.isError = true;
      } finally {
        this.isLoading = false;
        this.showLoading = false;
      }
    },
    async addOrRemoveContentLike() {
      if (this.results.UserHasLiked) {
        this.$commentService.RemoveLike(this.contentGUID);
      } else {
        this.$commentService.AddLike(this.contentGUID);
      }
    },
    update(value) {
      if (value) {
        this.fetchData(false);      
      }
    },
    loading(value) {
      this.showLoading = value;
    }
  }
}
</script>