﻿$(document).ready(function () {
    setupKlaroObserver();
    setupKlaroAccessibility();

    $('body').on('click.collapse-next.data-api', '[data-toggle=collapse-next]', function(e) {
        var $target = $(this).siblings('.collapse');
        $target.collapse('toggle');

        $(this).attr('aria-expanded', function(i, attr) {
            return attr == 'true' ? 'false' : 'true';
        });
    });

    if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident') > 0) {
        $('#gotonav').click(function () {
            $('#navigation').attr('tabIndex', -1).focus();
        });
        $('#gotocontent').click(function () {
            $('#main-content').attr('tabIndex', -1).focus();
        });
    }

    // Init reflow colummns on NARIC education information tables
    $('.js-naric-education-table table').reflowColumns();

    // Init footnotes in main content
    $('.main-content').citeToFootnote();

    $(".footer-column").find("a[href^='https://www.facebook.com']").addClass("preventAutomaticIcon").not(":has('img')").prepend("<svg class='icon icon-facebook automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-facebook'></use></svg>");
    $(".footer-column").find("a[href^='https://www.linkedin.com']").addClass("preventAutomaticIcon").not(":has('img')").prepend("<svg class='icon icon-linkedin automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-linkedin'></use></svg>");
    $(".footer-column").find("a[href^='https://www.twitter.com']").addClass("preventAutomaticIcon").not(":has('img')").prepend("<svg class='icon icon-twitter automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-twitter'></use></svg>");
    $(".footer-column").find("a[href^='https://www.youtube.com']").addClass("preventAutomaticIcon").not(":has('img')").prepend("<svg class='icon icon-film automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-film'></use></svg>");

    // Set icon and sr-tag for each language on all external links in TinyMCE-areas. (This solution is copied from the old site.)
    var translate = getLocalizedOpenInNewWindow();

    //$("a[href^='https:']").not("[href   ='" + window.location.hostname + "']").not(":has('img')").not(".preventAutomaticIcon").append("<span class='icon icon-extern-lank-uhr automatic'><span class='sr-only'>"+ translate);
    //$("a[href^='http:']").not("[href*='" + window.location.hostname + "']").not(":has('img')").not(".preventAutomaticIcon").append("<span class='icon icon-extern-lank-uhr automatic'><span class='sr-only'>"+ translate);
    //Create svg icon on every extern-lank without the class name "prevetAutomaticIcon" 
    $("a[href^='https:']").not("[href*='" + window.location.hostname + "']").not(":has('img')").not(".preventAutomaticIcon").append("<svg class='icon icon-extern-lank automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-extern-lank'></use></svg><span class='sr-only'>" + translate);
    $("a[href^='http:']").not("[href*='" + window.location.hostname + "']").not(":has('img')").not(".preventAutomaticIcon").append("<svg class='icon icon-extern-lank automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-extern-lank'></use></svg><span class='sr-only'>" + translate);

    
    // Show title text below images in TinyMCE-areas, if editor entered a text in the title field
    $("img").each(function () {

        var title = $(this).attr("title");

        if (title != null && title.length > 0) {
            $(this).wrap("<div class='imageWithTitle'></div>");

            var style = $(this).attr("style");
            var width = $(this).attr("width");

            $(this).parent().attr("style", style);
            $(this).parent().css("width", width);
            $(this).parent().append("<div class='imageTitle'>" + title + "</div>");
        } 
    });

    //truncate text in hero (only new design for startpage 2018)
    $('.truncate-hero > p').each(function () {
        if ($(window).width() < 1199 && $(window).width() > 991 && this.innerText.length > 150) {
            this.innerText = this.innerText.substring(0, 145) + "...";
        } else if (this.innerText.length > 190 && $(window).width() > 991) {
            this.innerText = this.innerText.substring(0, 190) + "...";
        } 
    });

    var $popUpButtons = $('.js-popup');
    $popUpButtons.on('click', showPopUp);

    function showPopUp(event) {
        var width = 575,
        height = 400,
        left = ($(window).width() - width) / 2,
        top = ($(window).height() - height) / 2,
        url = this.href,
        opts = 'status=1' +
                 ',width=' + width +
                 ',height=' + height +
                 ',top=' + top +
                 ',left=' + left;

        window.open(url, '', opts);

        event.preventDefault();
        return false;
    }
});

function setupKlaroObserver() {
    const klaroDiv = document.getElementById("klaro");

    if (klaroDiv) {
        const cookieModalElements = klaroDiv.querySelectorAll(".cookie-modal");

        if (cookieModalElements.length > 0) {
            const observer = new MutationObserver(function (mutationsList) {
                for (const mutation of mutationsList) {
                    if (mutation.type === "childList") {
                            setupKlaroAccessibility();
                    }
                }
            });

            const config = {
                attributes: true,
                childList: true,
                subtree: true,
            };

            observer.observe(klaroDiv, config);
        }
    }
}

var klaroLinkElementId = null;

function getLocalizedOpenInNewWindow() {
    var translate = "";
    if (window.location.href.indexOf('/en/') != -1) {
        translate = "Open in new window";
    } else if (window.location.href.indexOf('/ar/') != -1) {
        translate = "فتح نافذة جديدة";
    } else if (window.location.href.indexOf('/fa/') != -1) {
        translate = "باز کردن در پنجره جدید";
    } else {
        translate = "Öppna i nytt fönster";
    }
    return translate;
}

var activeElement = document.activeElement;

function setupKlaroAccessibility() {
    const cookieModal = document.querySelector(".cookie-modal");
    
    if (cookieModal) {

        var translate = getLocalizedOpenInNewWindow();
        var externalIcon = $("<svg class='icon icon-extern-lank automatic' aria-hidden='true' focusable='false'><use xlink:href='/assets/svg/uhriconsprite.svg#icon-extern-lank'></use></svg><span class='sr-only'>" + translate + "</span>");
        $(".cm-klaro a[href^='https:']").not("[href*='" + window.location.hostname + "']").not(":has('svg')").append(externalIcon);
        $(".cm-klaro a[href^='http:']").not("[href*='" + window.location.hostname + "']").not(":has('svg')").append(externalIcon);

        if (klaroLinkElementId) {         
            var closeButton = $('#klaro .close');
            if (closeButton) {
                closeButton.on('click', function () {
                    $(klaroLinkElementId).focus();
                });

                closeButton.on('keyup', function (event) {
                    if (event.key === 'Enter') {
                        $(klaroLinkElementId).focus();
                    }
                });
            }

            var saveButton = $('#klaro .cm-btn-accept');
            if (saveButton) {
                saveButton.on('click', function () {
                    $(klaroLinkElementId).focus();
                });

                saveButton.on('keyup', function (event) {
                    if (event.key === 'Enter') {
                        $(klaroLinkElementId).focus();
                    }
                });
            }
        }

        // Select the element
        const labelElement = document.querySelector(".cm-list-label");

        // Check if the element exists and remove the tabindex attribute
        if (labelElement) {
            labelElement.removeAttribute("tabindex");
        }

        const focusableElements = Array.from(
            cookieModal.querySelectorAll(
                'button:not([disabled]), [href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
            )
        );

        let currentIndex = -1;

        const firstFocusable = focusableElements[0];
        const lastFocusable = focusableElements[focusableElements.length - 1];

        // Set initial focus on the first focusable element
        firstFocusable.focus();

        focusableElements.forEach((element, index) => {
            element.addEventListener('click', () => {
                currentIndex = index;
            });
        });

        cookieModal.addEventListener("keydown", function (event) {
            if (event.key === "Tab") {
                // Prevent default tab behavior
                event.preventDefault();
                activeElement = document.activeElement;

                if (event.shiftKey) {
                    // Shift + Tab
                    if (activeElement === firstFocusable) {
                        // Move focus to the last focusable element within modal
                        lastFocusable.focus();
                        currentIndex = focusableElements.length - 1;
                    } else {
                        // Move focus to the previous focusable element
                        currentIndex =
                            (currentIndex - 1 + focusableElements.length) %
                            focusableElements.length;
                        focusableElements[currentIndex].focus();
                    }
                } else {
                    // Tab
                    if (currentIndex + 1 === focusableElements.length) {
                        // Move focus to the first focusable element within modal
                        firstFocusable.focus();
                        currentIndex = 0;
                    } else {
                        // Move focus to the next focusable element
                        currentIndex = (currentIndex + 1) % focusableElements.length;
                        focusableElements[currentIndex].focus();
                    }
                }
            }
        });
    }
}
function focusSearch() {
    if (document.getElementById("mobile-search-button").classList.contains("collapsed")) {
        setTimeout(setFocusSearch, 200);
    }
}
function setFocusSearch() {
    document.getElementById("search-phone").getElementsByClassName("search-box")[0].focus();
}