<template>
  <div v-if="Likes && Likes.length > 0" class="modal fade" :id="ModalDialogId" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header comment-modal-header uhrcolor-petroleum-background uhrcolor-vit-text">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><svgIcon iconName="kryss"></svgIcon></button>
            </div>
            <div class="modal-body">
              <h2 class="modal-title">{{ Likes.length }} personer gillar detta inlägg</h2>
              <div v-for="like in Likes" :key="like.Id" style="display: flex;align-items: center;gap: 2rem;margin: 1rem auto;">
                <div class="comment-profileImage">
                  <ProfilePhoto :PhotoString="like.Author.PhotoString"></ProfilePhoto>
                </div>
                <profilelink :Author="like.Author"></profilelink>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Stäng</button>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import svgIcon from '../components/svgicon.vue';
import profilelink from '../components/profilelink.vue';
import ProfilePhoto from '../components/profilePhoto.vue';

export default {
  name: 'pagelikemodal',
    props: {
      ModalDialogId: String,
      Likes: Array,
      ProfilePhoto: String
    },
    components: {
      svgIcon,
      profilelink,
      ProfilePhoto
    }
  }
</script>