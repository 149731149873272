﻿// Filter a list of DOM items by X number of parameters
// Ex. usage with no parameters:
// $('.country-selector-wrapper').simpleFilter();
// 
// Ex. usage with all parameters:
// $('.country-selector-wrapper')
//    .simpleFilter({
//                   activeClass: 'active',                  // This class will be applied to the active filter buttons/links etc.
//                   filterValAttr: 'data-filter-val',       // The name of attribute that the filter value should be stored in on both the action DOM item and the list DOM item
//                   filterParam: 'data-filter-param',       // The name of attribute that the filter parameter should be stored in on both the action DOM item and the list DOM item
//                   filterSep: ',',                         // The separator that is used on list items in attribute defined by filterValAttr ex,  data-filter-val="U,North America"
//                   actionsSel: '.js-filter-action',        // The selector for the filter buttons/links
//                   listItemSel: '.js-filter-item'          // The selector for the items to filter
//                 });
// HTML:
//<div class="country-selector-wrapper">
//    <!-- Filter "buttons" for filter parameter 1 -->
//    <div>
//        <div class="js-filter-action" data-filter-val="North America" data-filter-param="continent">Nordamerika</div>
//        <div class="js-filter-action" data-filter-val="South America" data-filter-param=="continent">Sydamerika</div>
//    </div>
//      
//    <!-- Filter "buttons" for filter parameter 2 -->
//    <div>
//        <div class="js-filter-action" data-filter-val="A" data-filter-param=="letter">A</div>
//        <div class="js-filter-action" data-filter-val="U" data-filter-param=="letter">U</div>
//    </div>
//     
//    <!-- The list of DOM items to apply the filter on -->
//    <div>
//        <div class="js-filter-item" data-filter-val="U,North America">USA</div>
//        <div class="js-filter-item" data-filter-val="A,South America">Argentina</div>
//    </div>
//</div>



;
(function ($, window, document, undefined) {

    "use strict";

    // Create the defaults once
    var pluginName = "simpleFilter",
        defaults = {
            activeClass: 'active',                  // This class will be applied to the active filter buttons/links etc.
            filterValAttr: 'data-filter-val',       // The name of attribute that the filter value should be stored in on both the action DOM item and the list DOM item
            filterParam: 'data-filter-param',       // The name of attribute that the filter parameter should be stored in on both the action DOM item and the list DOM item
            filterSep: ',',                         // The separator that is used on list items in attribute defined by filterValAttr ex,  data-filter-val="U,North America"
            actionsSel: '.js-filter-action',        // The selector for the filter buttons/links
            listItemSel: '.js-filter-item',          // The selector for the items to filter
            hideListItemClass: 'js-filter-hide',
            groupSel: '.js-filter-group',
            emptyGroupClass: 'js-filter-empty-group',
            emptyResultSel: '.js-filter-empty-result'
        };

    // The actual plugin constructor
    function Plugin(element, options) {
        this.element = element;

        // Merge defaults and options
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;

        // Private varibles
        this.$actions = [];
        this.$listItems = [];
        this.filterTypes = {};

        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function () {

            var self = this,
                $element = $(self.element),
                settings = self.settings;

            self.$actions = $element.find(settings.actionsSel);
            self.$listItems = $element.find(settings.listItemSel);

            // Init click listeners
            self.$actions.bind('click', function () {
                self.updateFilter(self, $(this));
                self.updateGroupWrapperClasses(self, $(this));
                self.updateProspectiveSelectorStates(self);
            });

            self.updateProspectiveSelectorStates(self);
        },

        updateGroupWrapperClasses: function (self) {
            $(self.element).find(self.settings.groupSel).each(function () {
                var groupWrapper = $(this);
                var isEmpty = groupWrapper.find(self.settings.listItemSel).not('.' + self.settings.hideListItemClass).length === 0;
                groupWrapper.toggleClass(self.settings.emptyGroupClass, isEmpty);
            });
        },
        
        handleSelectorState: function ($clickedItem) {
            var siblings = $clickedItem.siblings();

            // Remove and toggle actice class SVG safe
            this.removeClass(siblings, this.settings.activeClass);
            this.toggleClass($clickedItem, this.settings.activeClass);
        },

        toggleClass: function ($itemArr, cssClass) {
            $itemArr.each(function() {
                var $item = $(this);
                var presentClasses = $item.attr('class') || '';
                var activeIndex = presentClasses.indexOf(cssClass);
                presentClasses = (activeIndex === -1) ? presentClasses + ' ' + cssClass : presentClasses.replace(cssClass, '');
                $item.attr('class', presentClasses);
            });
        },

        removeClass: function($itemArr, cssClass) {
            $itemArr.each(function () {
                var $item = $(this);
                var presentClasses = $item.attr('class') || '';
                var activeIndex = presentClasses.indexOf(cssClass);
                presentClasses = (activeIndex === -1) ? presentClasses : presentClasses.replace(cssClass, '');
                $item.attr('class', presentClasses);
            });
        },

        filterList: function () {
            var self = this;
            self.$listItems.each(function (index) {
                var match = true,
                    $item = $(this),
                    itemFilterArr = $item.attr(self.settings.filterValAttr).split(self.settings.filterSep);
                $.each(self.filterTypes, function (i, val) {
                    match = val ? ($.inArray(val, itemFilterArr) !== -1) : true;
                    return match;
                });

                // Toggle visibility of item
                $item.toggleClass(self.settings.hideListItemClass, !match);
            });
        },

        updateProspectiveSelectorStates: function(self) {
            self.$actions.each(function () {
                var $el = $(this);
                var match = self.wouldHaveMatch($el);
                $el.toggleClass(self.settings.emptyGroupClass, !match);
            });
        },

        wouldHaveMatch: function ($el) {
            var self = this,
                clickedFilterType = $el.attr(self.settings.filterParam),
                clickedFilterVal = $el.attr(self.settings.filterValAttr);

            var tempFilterTypes = $.extend({}, self.filterTypes);
            tempFilterTypes[clickedFilterType] = tempFilterTypes[clickedFilterType] === clickedFilterVal ? '' : clickedFilterVal;

            var matches = self.$listItems.map(function (index) {
                var match = true,
                    $item = $(this),
                    itemFilterArr = $item.attr(self.settings.filterValAttr).split(self.settings.filterSep);
                $.each(tempFilterTypes, function (i, val) {
                    match = val ? ($.inArray(val, itemFilterArr) !== -1) : true;
                    return match;
                });

                return match ? 1 : null;
            });

            return matches.length > 0;
        },

        handleEmptyResult: function (self) {
            var hasResults = self.$listItems.filter(':visible').length > 0;
            $(self.settings.emptyResultSel).toggle(!hasResults);
        },

        updateFilter: function (self, $el) {
            var clickedFilterType = $el.attr(self.settings.filterParam),
                clickedFilterVal = $el.attr(self.settings.filterValAttr);

            self.filterTypes[clickedFilterType] = self.filterTypes[clickedFilterType] === clickedFilterVal ? '' : clickedFilterVal;
            self.handleSelectorState($el);
            self.filterList();

            // Wait for the next event loop
            setTimeout(function() {
                self.handleEmptyResult(self);
            },0);
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);