﻿
var alternativeSelectionForm = (function($) {

    var $blockWrapper,
        $typeOfPermit;

    function init() {
        $blockWrapper = $(".js-alternative-selection-form");
        if (!$blockWrapper.length) {
            return;
        }

        $typeOfPermit = $('#TypeOfPermit');
        $education = $('#Qualification');

        handlePreviousApplicationNumberVisibility();
        handleShareOfPlaceaVisibility();
        handleNameOnEducationVisisbility();

        initListeners();

    }

    function initListeners() {
        $typeOfPermit.on('change', handlePreviousApplicationNumberVisibility);
        $typeOfPermit.on('change', handleShareOfPlaceaVisibility);
        $education.on('change', handleNameOnEducationVisisbility);
    }

    function handlePreviousApplicationNumberVisibility() {
        var val = $typeOfPermit.find('option:selected').val();
        var groupId = val.split("|")[0];
        var showPreviousNumber = (groupId === "2982F5F6-00AE-4BBE-A3EE-8BC904D67138");
        $('.js-previous-application-number-wrapper').toggle(showPreviousNumber);        
    }

    function handleNameOnEducationVisisbility() {
        var val = $education.find('option:selected').val();
        var groupId = val.indexOf("|") > -1 ? val.split("|")[0] : "";
        var showNameOnEducation = (groupId === "" || groupId === "FC9A122F-FA10-482C-BEFD-0265ED066575" || groupId === "B51A3CAB-B2A1-48C0-A6EC-E67F62A0156C");
        $('.js-education-wrapper').toggle(showNameOnEducation);
    }

    function handleShareOfPlaceaVisibility() {
        var selected = $typeOfPermit.find('option:selected');
        var val = selected.val();
        var text = selected.text();
        var groupId = val.indexOf("|") > -1 ? val.split("|")[0] : "";
        var showPercentBox = (groupId === "3CCB03B7-4801-4CF5-A31C-4D44C6C2D01C" && text.toLowerCase().indexOf("alternativt urval") > -1);
        $('.js-percent-wrapper').toggle(showPercentBox);
    }

    return {
        init: init
    }
})(jQuery);


$(function () {
    alternativeSelectionForm.init();
});