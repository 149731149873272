<template>
  <div class="comment-editform">
    <div class="comment-editrow">
      <textarea
        v-model="commentText"
        placeholder=""
        :maxlength="maxlength"
        style="width:100%"
        :aria-invalid="validated">>
      </textarea>
      <div class="comment-error" v-if="validated" role="alert">
          <span>Du har inte skrivit någon kommentar.</span>
      </div>          
    </div>  
    <div class="comment-editrow">
      <button
        class="btn btn-primary font-weight-bold font-size16" 
        @click="updateComment">
        Redigera
      </button>
      <button
        class="btn btn-default font-weight-bold font-size16" 
        @click.prevent="cancel"
        :disabled="this.isLoading">
        Avbryt
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'editform',
  props: {
      commentId: Number,
      editCommentText: String,
      contentGUID: String,
      createdDate: String
  },
  data: function() {
    return {
        commentText: this.editCommentText,
        isLoading: false,
        isValidationAllowed: false,
        maxlength: 1999
    }
  },
  computed: {
    validated() {
      return this.isValidationAllowed && !this.commentText
    }
  },
  methods:{
    async updateComment() {
      try {
        this.validate();
        if(!(this.isValidationAllowed && !this.commentText)) {
          this.isLoading = true;
          this.$emit('loading', true);
          this.$commentservice.EditComment({
              Id: this.commentId,
              Guid: this.contentGUID,
              Text: this.commentText
            }).then((response) => {
            this.$emit('update', true);
            this.$emit('editcomment', false);  
          });     
        }   
      } catch (err) {
        this.isError = true;
        this.isLoading = false;
        this.isValidationAllowed = false;
        this.$emit('loading', false);
      } finally {
        
      }
    },
    cancel() {
      this.$emit('editcomment', false);
    },
    validate() {
      this.isValidationAllowed = true;
    }
  }
}
</script>