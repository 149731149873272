<template>
  <component :is="IsValidUser  ? 'a' : 'span'" :class="{CustomClass, 'text-decoration-underline': IsValidUser}" :href="IsValidUser ? Author.SharePointUrl: undefined" :target="IsValidUser ? '_blank' : undefined">{{ text }}</component>  
</template>
<script>

export default {
  name: 'profilelink',
  props: {
    Author: Object,
    ShowProfileImage: Boolean,
    CustomClass: String
  },
  data: function() {
    return {
    }
  },
  components: {

  },
  computed: {
    IsValidUser() {
      return this.Author && this.Author.ValidUser;
    },
    text() {
      if (!this.Author) {return '';}
      return this.Author.ValidUser ? this.Author.Name : this.Author.Email;
    }
  }
}
</script>