<template>
<div class="modal fade" :id="modalDialogid" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header comment-modal-header uhrcolor-petroleum-background uhrcolor-vit-text">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><svgIcon iconName="kryss"></svgIcon></button>
        <!-- <h4 class="modal-title">Ta bort kommentar</h4> -->
      </div>
      <div class="modal-body">
        <p>Är du säker på att du vill ta bort kommentaren?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="deleteComment">Ja, ta bort</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Avbryt</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
</template>
<script>
import svgIcon from '../components/svgicon.vue';

export default {
  name: 'commentdelete',
  props: {
      commentId: Number,
      contentGUID: String,
  },
  data: function() {
    return {
        isLoading: false
    }
  },
  components: {
    svgIcon
  },
  computed: {
    modalDialogid: function () {
      return 'commentdeletemodal-' + this.commentId;
    }
  },
  methods:{
    deleteComment() {
      this.$commentservice.RemoveComment(this.commentId).then((res) => {
        this.$emit('update', true);
      });
    }
  }
}
</script>