<template>
	<img v-if="PhotoString" :src="`data:img/jpeg;base64,${PhotoString}`" alt="" />
    <img v-else src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqehdPiDwe_KT7gCB64TEtpRKrKvDEJxp06Q&usqp=CAU" alt="" />
</template>

<script>
export default {
	name: 'profilePhoto',
	props: {
		PhotoString: String
	}
}
</script>