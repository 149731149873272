﻿
var searchevaluationpage = (function () {

    function init() {
        if ($('.search-evaluation-page').length === 0) {
            return;
        }

        initListeners();
    }


    function initListeners() {
        $(document).on('click', '.js-next-results-button', loadMoreResults);
    }


    function loadMoreResults(event) {
        var $clickedItem = $(this);
        $clickedItem.addClass("loading");

        $.ajax({
            url: $clickedItem.attr('data-ajax-url'),
            method: "GET",
            dataType: "html",
            success: addResultToDom($clickedItem)
        });

        event.preventDefault();
    }


    function addResultToDom($clickedItem) {
        return function (response) {
            $clickedItem.before(response);
            $clickedItem.remove();
        }
    }

    return {
        init: init
    }
})();

// Init on page load
(function ($) {
    $(searchevaluationpage.init);
})(jQuery);