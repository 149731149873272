﻿declare global {
  var uhr: {
    ready: (callback: () => void) => void
  },
  jQuery: any;
  interface Window { Cookies: any; }
}



//library
import "./Scripts/prebuilt/jquery-ui.js" //Byggs från ovan och kopiers till prebuilt
import "./Scripts/jquery.validate.js"
import "./Scripts/jquery.validate.unobtrusive.js"
import "./Scripts/jquery.reflowcolumns.js" //main.js
import "./Scripts/jquery.resizeend.mod.js" //sunburstchart
import "./Scripts/jquery.simplefilter.js" //countryselectorblock
import "./Scripts/jquery.citetofootnote.js" //main
import "./Scripts/_extensions.js"
import "./Bootstrap/js/bootstrap.js"
import "./Scripts/autocomplete.js"         // This one is for "normal" search
import "./uhrdictionary/autocomplete.js"   // This one is for the dictionary
import "./Scripts/progress-circle.js"

//site
import "./Scripts/site/education-comparison.js"
import "./Scripts/site/foreigngradecalculator.js"
import "./Scripts/site/navigation.js"
import "./Scripts/site/sunburstchart.js"
import "./Scripts/site/searchpage.js"
import "./Scripts/site/searchevaluationpage.js"
import "./Scripts/site/altarnativeselectionform.js"
import "./Scripts/site/countryselectorblock.js"
import "./Scripts/site/iframe-uhr.js"
import "./Scripts/site/main.js" //SKA MÄ

import "./Scripts/site/mediablock.js"
import "./Scripts/site/columnbasedlandingpage.js"
import "./Scripts/antagningsstatistik.sokblock.js"
import "./Scripts/programpagelist.js"
import Cookies from "js-cookie";

import "/js/main.js" //endast insidan egentligen

import "./Styles/Bootstrap.custom.less"
import "./Styles/Site.less"
import "./Styles/cssthemes/syv/theme.less"

window.Cookies = Cookies;


//CÅ: Det här finns inte.. vet inte var det tagit vägen.. 
//import followSidebar from './Scripts/site/follow-sidebar.js';
//followSidebar($); -> Ersatt med nedan
//followSidebar  innehåller det här. hämtat fr prod
(function ($) {

  if ($(".letter-selector")[0]){
      var element = $(".letter-selector");
      if(element){
        const originalY = element.offset().top;

        $(window).on("scroll", function (event) {
            var scrollTop = $(window).scrollTop();
  
            $(window).scroll(function () {
                if ($(this).scrollTop() > originalY - 25) {
                    $(".letter-selector").addClass("letter-selector-fixed");
                } else {
                    $(".letter-selector").removeClass("letter-selector-fixed");
                }
            });
        });
  
      }
  }
})(jQuery);