$(function () {
    var s = getComputedStyle(document.documentElement),
        sizeBreakpoints = {
            xsmax: s.getPropertyValue('--size-xs-max').trim(),
            smmin: s.getPropertyValue('--size-sm-min').trim(),
            smmax: s.getPropertyValue('--size-sm-max').trim(),
            mdmin: s.getPropertyValue('--size-md-min').trim(),
            mdmax: s.getPropertyValue('--size-md-max').trim(),
            lgmin: s.getPropertyValue('--size-lg-min').trim(),
        };

    $.fn.scrollPosReaload = function () {
        if (localStorage) {
            var posReader = localStorage["posStorage"];
            if (posReader) {
                $("html, body").animate({ scrollTop: posReader }, 100);
                localStorage.removeItem("posStorage");
            }
            $(this).click(function (e) {
                localStorage["posStorage"] = $(window).scrollTop();
            });

            return true;
        }
        return false;
    }
    $.fn.AriaHasPopupController = function () {
        var isSmallScreen = window.matchMedia("screen and (max-width: " + sizeBreakpoints.xsmax + ")");
        $("[disableAriaHasPopupForSmallScreens|='true']")
            .attr("aria-haspopup", isSmallScreen.matches ? "false" : "menu");
    }
    $(window).resize(function () {
        $(window).AriaHasPopupController();
    })

    $(document).ready(function () {
        // Lägg till element som triggar reload
        $('.dictionary-language').scrollPosReaload();
        $('.classification').scrollPosReaload();
        $('.letter-selection').scrollPosReaload();
        $('#btnSearch').scrollPosReaload();
        $('#query').scrollPosReaload();
        $('#content-sr').hide();

        // Saker som är skärmbreddsberoende
        $(window).AriaHasPopupController()

        if ($(".uhrdictionary-word").length) {
            $('html,body').animate({ scrollTop: $(".uhrdictionary-word").offset().top }, 100);
        }

        $(".feedback-post-submit").hide();

        //Trunkera xhtml-ytan under huvudrubrik
        $(".txtcol").click(function () {
            var m = $('#show-more');
            if ($(this).prev().hasClass("truncate")) {
                m.attr("aria-expanded", false);
                m.find('.text').html(m.attr("data-more-text"));
            } else {
                m.attr("aria-expanded", true);
                m.find('.text').html(m.attr("data-less-text"));
            }
            $('#content-sr').toggle();
            $("#preamble-content").toggleClass("truncate");
        });

        // Create a MutationObserver to watch for changes in the DOM
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes.length) {
                    // Check if the mutation is a 'childList' change and if the target matches your specific element
                    if (mutation.type === 'childList' && mutation.target.matches('div.Form__Status__Message.Form__Success__Message')) {

                        updateFeedbackVisibility();  // Execute if the mutation's target is the specified element
                    }
                }
            });
        });

        const config = { childList: true, subtree: true };
        observer.observe(document.body, config);
    });

    function updateFeedbackVisibility() {
        const successMessage = document.querySelector('.Form__Success__Message');
        if (successMessage) {
            // Handle multiple .feedback-pre-submit elements
            const feedbackPreSubmits = document.querySelectorAll('.feedback-pre-submit');
            feedbackPreSubmits.forEach(feedbackPreSubmit => {
                feedbackPreSubmit.style.display = 'none'; // Hide each pre-submit feedback element
            });

            // Handle multiple .feedback-post-submit elements
            const feedbackPostSubmits = document.querySelectorAll('.feedback-post-submit');
            feedbackPostSubmits.forEach(feedbackPostSubmit => {
                feedbackPostSubmit.style.display = 'block'; // Show each post-submit feedback element
            });
        }
    }

    $("input[type=radio].dictionary-language").change(function () {
        var currentLocation = window.location;
        var url = new URL(currentLocation);
        if ($(this).val() === "sv") {
            $('#langSvLabel').addClass('selected');
            $('#langEnLabel').removeClass('selected');
            $('.letter-selection-swedish').addClass('in');
            $('.letter-selection-english').removeClass('in');
            let href = $('.language-classification').attr('href');
            let newHref = href.replace('lang=en', 'lang=sv');
            $('.language-classification').attr('href', newHref);
            if (url.href.includes('/classification?')) {
                let newHref = url.href.replace('lang=en', 'lang=sv');
                window.location.href = newHref;
            } else if (url.href.includes('/letters?')) {
                let newHref = url.href.replace('lang=en', 'lang=sv');
                window.location.href = newHref;
            }
        } else {
            $('#langSvLabel').removeClass('selected');
            $('#langEnLabel').addClass('selected');
            $('.letter-selection-swedish').removeClass('in');
            $('.letter-selection-english').addClass('in');
            let href = $('.language-classification').attr('href');
            let newHref = href.replace('lang=sv', 'lang=en');
            $('.language-classification').attr('href', newHref);
            if (url.href.includes('/classification?')) {
                let newHref = url.href.replace('lang=sv', 'lang=en');
                window.location.href = newHref;
            } else if (url.href.includes('/letters?')) {
                let newHref = url.href.replace('lang=sv', 'lang=en');
                window.location.href = newHref;
            }
        }
        
    });

    if ($("#query").length === 0) {
        return;
    }

    var initialQuery = "";

    $("#query").autocomplete({
        source: function (request, response) {
            var query = $("#uhrdictionary-baseurl").val() + "searchjson";
            var selectedLang = $("input[type=radio].dictionary-language").filter(":checked").val();
            $.ajax({
                type: 'POST',
                url: query,
                data: { lang: selectedLang, term: $("#query").val() },
                success: function (data) {
                    response(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $('.uhrdictionary-main-content').html("<p style='color: #b00060'>N&aring;got gick fel. F&ouml;rs&ouml;k igen senare.</p>");
                },
                dataType: 'json'
            });
        },
        html: true,
        minLength: 3,
        delay: 50,
        appendTo: "#search_results",
        select: function (event, ui) {
            var url = ui.item.term;

            if (url === "showall") {
                url = initialQuery;
            }

            var basePart = $("#uhrdictionary-baseurl").val();
            var queryPart = '?q=' + url + '&lang=' + $('#uhrdictionary-lang').val();
            var completeUrl = basePart + 'freetextsearch' + queryPart;

            window.location = completeUrl;
            return false;
        },
        focus: function (event, ui) {
            event.preventDefault();
        },
        open: function (event, ui) {
            if (window.matchMedia("(max-width: 767px)").matches) {
                $('html, body').animate({
                    scrollTop: $("#query").offset().top
                },
                    200);
            }
            initialQuery = $("#query").val();
            $('.loader').hide();
        }
    }).data("uiAutocomplete")._renderItem = function (ul, item) {
        var search = this.term.toLowerCase();

        var highlighted = item.term.toLowerCase()
            .split(search)
            .join('<strong>' + search + '</strong>');

        if (item.term === "showall") {
            return $('<li class="custom-complete-choice show-all-li" role="option" aria-selected="false" name="Visa alla">')
                .append($('<a href="#" class="custom-complete-link show-all-button"></a>').html(item.label))
                .appendTo(ul);
        }
        var lang = $('#uhrdictionary-lang').val();
        return $('<li class="custom-complete-choice" lang="' + lang +'" role="option" aria-selected="false" name="Visa ' + item.term + '">')
            .append($('<a href="#" class="custom-complete-link"></a>').html(highlighted))
            .appendTo(ul);
    };
});
